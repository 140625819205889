import React from 'react';
import CategoryLabel from "../CategoryLabel/CategoryLabel";
import {CategoryType} from "../../../types/CategoryType";

import "./CategoryList.scss"

type CategoryListParams = {
    categories: CategoryType[],
    onClickLabel?: (category: CategoryType) => void
}

function CategoryList({categories, onClickLabel}: CategoryListParams) {
    return (
        <div className={'categories-list'}>
            {
                categories.map(category =>
                    <CategoryLabel category={category}
                                   onClickCategory={onClickLabel}
                                   key={`category-${category.id}`}/>)
            }
        </div>
    )
}

export default CategoryList;
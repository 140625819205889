import {useDatabaseConnector} from "./useDatabaseConnector";
import {CategoryType} from "../../types/CategoryType";
import {ConflictError} from "../../errors/ConflictError";
import {useCallback, useMemo} from "react";
import {AxiosResponse} from "axios";

export const useCategoryEndpoints = (token: string, abortController: AbortController | null = null) => {
    const dbConnector = useDatabaseConnector(token, abortController)

    const getCategory = useCallback((
        category_id: number
    ) => {
        return dbConnector.get(
            "/category",
            {
                params: {
                    id: `eq.${category_id}`
                }
            }
        ).then(response => {
            const data = response.data
            if (data.length < 1) {
                throw Error(`no category with id '${category_id}' found`)
            }

            let category: CategoryType = {
                id: data[0].id,
                name: data[0].name,
                rgb_color_code: data[0].rgb_color_code
            };
            return category
        })
    }, [dbConnector])

    const getMultipleCategories = useCallback((
        category_ids: number[]
    ) => {
        const promises = category_ids.map(id => getCategory(id))

        return Promise.all(promises)
    }, [getCategory])

    const getAllCategories = useCallback(() => {
        return dbConnector.get(
            "/category",
            {
                params: {
                    order: "id.asc"
                }
            }
        ).then(response => {
            return response.data
        })
    }, [dbConnector])

    const createCategory = useCallback((
        name: string, rgb_color_code: string
    ) => {
        return dbConnector.post(
            "/category",
            {
                name,
                rgb_color_code
            }
        ).catch(error => {
            const HTTP_CONFLICT = 409
            if (error.response.status === HTTP_CONFLICT) {
                throw new ConflictError("A category with this name does already exist!")
            }
        })
    }, [dbConnector])


    /**
     * This function updates an existing category.
     * It changes the name and rgb color code
     *
     * @param id the id of the category that should be updated
     * @param new_name the new name for this category
     * @param new_rgb_color_code the new rgb color code
     */
    const updateCategory = useCallback((
        id: number,
        new_name: string,
        new_rgb_color_code: string
    ): Promise<AxiosResponse<any, any>> => {

        return dbConnector.patch(
            "/category",
            {
                name: new_name,
                rgb_color_code: new_rgb_color_code
            },
            {
                params: {
                    id: `eq.${id}`
                }
            }
        )
    }, [dbConnector])

    /**
     * This function deletes a category.
     * If removeFromPrompts === true, this category is also removed from all prompts.
     * If removeFromPrompts === false and at least one prompt still has this category, this promise fails.
     *
     * @param id the id of the category that should be removed
     * @param removeFromPrompts whether this function should also remove the connection the prompts with this category.
     */
    const deleteCategory = useCallback((
        id: number,
        removeFromPrompts: boolean = false
    ): Promise<AxiosResponse<any, any>> => {
        const deleteCategory = () => dbConnector.delete(
            "/category",
            {
                params: {
                    id: `eq.${id}`
                }
            }
        )

        if (removeFromPrompts) {
            return dbConnector.delete(
                "/prompt_to_category",
                {
                    params: {
                        category_id: `eq.${id}`
                    }
                }
            ).then(deleteCategory)
        }

        return deleteCategory()
    }, [dbConnector])

    return useMemo(() => {
        return {createCategory, updateCategory, getCategory, getMultipleCategories, getAllCategories, deleteCategory}
    }, [createCategory, updateCategory, getCategory, getMultipleCategories, getAllCategories, deleteCategory])
}
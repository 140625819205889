import React from 'react';
import {Nav, Navbar, NavDropdown} from "react-bootstrap";
import {Link, NavLink} from "react-router-dom";

function Header() {
    return (
        <Navbar>
            <Navbar.Brand>
                <NavLink to={'/'}>
                    <img src={'JUN-logo2.png'}
                         height={'18pt'}
                         alt={'Logo Jun Rechtsanwälte'}
                    />
                </NavLink>
            </Navbar.Brand>
            <Navbar.Collapse>
                <Nav>
                    <NavDropdown title={"Prompts"}>
                        <NavDropdown.Item to={'/prompts'} as={Link}>
                            Alle Anzeigen
                        </NavDropdown.Item>
                        <NavDropdown.Item to={'/prompts/add'} as={Link}>
                            Hinzufügen
                        </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title={"Kategorien"}>
                        <NavDropdown.Item to={'/categories'} as={Link}>
                            Alle Anzeigen
                        </NavDropdown.Item>
                        <NavDropdown.Item to={'/category/add'} as={Link}>
                            Hinzufügen
                        </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title={"LLMs"}>
                        <NavDropdown.Item to={'/llms'} as={Link}>
                            Alle Anzeigen
                        </NavDropdown.Item>
                        <NavDropdown.Item to={'/llm/add'} as={Link}>
                            Hinzufügen
                        </NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default Header;
import "./CategoryEditableList.scss"
import React, {ChangeEventHandler, HTMLAttributes, useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import CategoryLabel from "../CategoryLabel/CategoryLabel";
import {CategoryType} from "../../../types/CategoryType";
import {useAuth} from "react-oidc-context";
import {useDatabase} from "../../../connectors/useDatabase";
import DeleteModal from "../../DeleteModal/DeleteModal";
import Toolbar from "../../Toolbar/Toolbar";
import {InputGroup, FormControl, ButtonGroup} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {FaCheck} from "react-icons/fa";
import {FaXmark} from "react-icons/fa6";


interface CategoryEditableListInputProps {
    category: CategoryType,
    onCancel: () => void,
    onSubmit: (category: CategoryType, newName: string, newColor: string) => void
}


/**
 * This component is used inside the CategoryEditableList when a category is being edited.
 * It is simple form for manipulating a category with callbacks for submission and cancel.
 *
 * @param category the current category that is being updated
 * @param onCancel callback that is called when the user cancels the editing
 * @param onSubmit callback that is called when the user submits the changes.
 *          The new name and color code is passed to this callback.
 * @constructor
 */
function CategoryEditableListInput({category, onCancel, onSubmit}: CategoryEditableListInputProps) {
    const [name, setName] = useState<string>(category.name)
    const [color, setColor] = useState<string>(category.rgb_color_code)

    const handleNameChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setName(event.currentTarget.value)
    }

    const handleColorChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setColor(event.currentTarget.value)
    }

    const handleClickSubmit = () => {
        onSubmit(category, name, color)
    }

    const handleClickCancel = () => {
        onCancel()
    }

    return <div className={'category-editable-list-input'}>
        <InputGroup className={'category-editable-list-input-group'}>
            <FormControl value={name}
                         onChange={handleNameChange}
            />
            <FormControl className={'category-editable-list-input-color'}
                         type={'color'}
                         value={color}
                         onChange={handleColorChange}
            />
        </InputGroup>
        <ButtonGroup>
            <Button variant={'light'} type={'submit'} onClick={handleClickSubmit}>
                <FaCheck aria-label={'Speichern'} className={'category-editable-list-input-submit'}/>
            </Button>
            <Button variant={'light'} type={'reset'} onClick={handleClickCancel}>
                <FaXmark aria-label={'Abbrechen'} className={'category-editable-list-input-cancel'}/>
            </Button>
        </ButtonGroup>
        <CategoryLabel className={'CategoryOverviewPage-category'}
                       category={{
                           id: category.id,
                           name,
                           rgb_color_code: color
                       }}
        />
    </div>
}

interface CategoryEditableListProps extends HTMLAttributes<HTMLDivElement> {

}

/**
 * This component lists all categories together with three buttons:
 *      1. An edit button
 *      2. A delete button
 *      3. A search button, that links to the prompt overview with an include filter for the respective category
 *
 * @param props properties that are directly passed to the enclosing up-most div. Can be used for custom styling.
 * @constructor
 */
function CategoryEditableList({...props}: CategoryEditableListProps) {
    const [categories, setCategories] = useState<CategoryType[]>([])
    // used to mark which categories are edited. Maps category ids to a boolean that states if this category is in edit mode
    const [editingCategories, setEditingCategories] = useState<Record<number, boolean>>({})

    // this state is used to communicate to the delete model which category should be deleted
    const [handledCategory, setCurrentCategory] = useState<CategoryType>()
    const [numPromptsWithHandledCategory, setNumPromptsWithHandledCategory] = useState<number>(0)
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

    const navigate = useNavigate()
    const auth = useAuth()
    const db = useDatabase(auth.user?.access_token ?? '')

    useEffect(() => {
        db.getAllCategories().then(setCategories)
    }, [db])

    const setEditMode = (category: CategoryType, editMode: boolean) => {
        let newEditingCategories = Object.assign({}, editingCategories)
        newEditingCategories[category.id] = editMode
        setEditingCategories(newEditingCategories)
    }

    const switchEditMode = (category: CategoryType) => {
        setEditMode(category, !editingCategories[category.id])
    }

    const handleDelete = (category: CategoryType) => {
        setCurrentCategory(category)
        db.getAllPromptIds([category.id]).then(prompt_ids => {
            setNumPromptsWithHandledCategory(prompt_ids.length)
            setShowDeleteModal(true)
        })
    }

    const handleSearch = (category: CategoryType) => {
        navigate(`/prompts?include=[${category.id}]`)
    }

    const handleConfirmDelete = () => {
        if (handledCategory !== undefined) {
            db.deleteCategory(handledCategory.id, true).then(() => {
                setShowDeleteModal(false)
                db.getAllCategories().then(setCategories)
            })
        }
    }

    const handleSubmitUpdate = (category: CategoryType, newName: string, newColor: string) => {
        db.updateCategory(category.id, newName, newColor).then(() => {
            setEditMode(category, false)
            db.getAllCategories().then(setCategories)
        })
    }

    return (
        <div {...props}>
            <table className={'show-separator'}>
                <tbody>
                {
                    categories.map(category => {
                        return <tr key={category.id}>
                            <td>
                                <Toolbar<CategoryType>
                                    className={'category-editable-list-toolbar'}
                                    referencedObject={category}
                                    onEdit={switchEditMode}
                                    onDelete={handleDelete}
                                    onSearch={handleSearch}
                                />
                            </td>
                            <td className={'category-editable-list-content'}>
                                {
                                    editingCategories[category.id] ?
                                        <CategoryEditableListInput category={category}
                                                                   onCancel={() => setEditMode(category, false)}
                                                                   onSubmit={handleSubmitUpdate}
                                        />
                                        :
                                        <Link to={`/prompts?include=[${category.id}]`}>
                                            <CategoryLabel category={category}
                                                           className={'CategoryOverviewPage-category'}/>
                                        </Link>
                                }

                            </td>
                        </tr>
                    })
                }
                </tbody>
            </table>

            <DeleteModal title={"Kategorie Löschen"}
                         onConfirmDelete={handleConfirmDelete}
                         show={showDeleteModal}
                         onHide={() => setShowDeleteModal(false)}
            >
                <p>
                    Soll die Kategorie <code>{handledCategory?.name}</code> tatsächlich gelöscht werden?
                </p>
                <p>
                    Diese Kategorie wird von <strong>{numPromptsWithHandledCategory} Prompts entfernt</strong>
                </p>
                <p>
                    Diese Aktion kann <strong>nicht rückgängig</strong> gemacht werden.
                </p>
            </DeleteModal>
        </div>
    )
}

export default CategoryEditableList;
import {TextPlaceholder, TextPlaceholderSubstitution} from "./TextPlaceholder";
import {InvalidArgument} from "../errors/InvalidArgument";
import _ from "lodash";

/**
 * This class represents a text (possibly) with placeholders, i.e., a template.
 * It only recognizes the placeholders that are specified via the placeholders field. This means, even if a substring
 * of the text has the correct format of a placeholder, it will not be replaced if the key of this placeholder is not
 * registered in the placeholders field.
 */
export class TemplatedText {
    get placeholders(): TextPlaceholder[] {
        return this._placeholders;
    }

    set placeholders(value: TextPlaceholder[]) {
        this._placeholders = value;
    }

    get text(): string {
        return this._text;
    }

    set text(value: string) {
        this._text = value;
    }

    private _text: string;
    private _placeholders: TextPlaceholder[]

    constructor(text: string, placeholder: TextPlaceholder[]) {
        this._text = text;
        this._placeholders = placeholder
    }

    /**
     * This function substitutes all registered placeholders with the passed values.
     * IMPORTANT: you must specify a value for each placeholder key.
     *
     * @param substitution an object that contains key-value pairs for each placeholder key.
     * @param throwError whether an error should be thrown if a substitution for a key of this templated text is missing
     *          If false, the key is substituted with its template string.
     */
    substitute(substitution: TextPlaceholderSubstitution, throwError: boolean = true): string {
        let substitutedText = this.text

        this.placeholders.forEach(placeholder => {
            if (!substitution.hasOwnProperty(placeholder.key)) {
                if (throwError) {
                    throw new InvalidArgument("substitution", `value for key '${placeholder.key}'`, `'${placeholder.key}' does not exist as key in substitution object.`)
                }
                // substitution is missing, but we do not throw an error => replace with template
                substitutedText = substitutedText.replaceAll(placeholder.getTemplateString(), placeholder.getTemplateString())
                return
            }
            substitutedText = substitutedText.replaceAll(placeholder.getTemplateString(), substitution[placeholder.key])
        })

        return substitutedText
    }

    splitTextAndPlaceholders(): { textComponents: string[], placeholderComponents: TextPlaceholder[] } {
        let textComponents: string[] = [this.text]
        let placeholderKeys: TextPlaceholder[] = []

        this.placeholders.forEach(placeholder => {
            let newTextComponents: string[] = []
            let newPlaceholders: TextPlaceholder[] = []
            textComponents.forEach((component, index) => {
                // we split the current component again according to the current placeholder
                let splitTextComponent = component.split(placeholder.getTemplateString())
                newTextComponents = newTextComponents.concat(splitTextComponent)

                // now we create an array that contains the placeholder key for each cut we did this round
                const numPlaceholders = splitTextComponent.length - 1
                let placeholdersForThisComponent = _.fill(Array(numPlaceholders), placeholder)

                // and we glue the whole array together by adding the placeholder keys for the current component
                // and the placeholder key of the cut between this component and the next component
                newPlaceholders = _.concat(newPlaceholders, placeholdersForThisComponent, placeholderKeys.slice(index, index + 1))
            })

            textComponents = newTextComponents;
            placeholderKeys = newPlaceholders
        })

        return {textComponents, placeholderComponents: placeholderKeys}
    }
}
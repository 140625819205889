import {useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";

/**
 * Use this hook to obtain the filter data from the current search parameters.
 * It reads the filter set by a PromptListFilter component.
 */
export default function usePromptListFilterData() {
    const [searchParams] = useSearchParams()
    const [includedCategoryIds, setIncludedCategoryIds] = useState<number[]>([])
    const [excludedCategoryIds, setExcludedCategoryIds] = useState<number[]>([])
    const [search, setSearch] = useState<string>("")

    useEffect(() => {
        setIncludedCategoryIds(JSON.parse(searchParams.get("include") ?? "[]"))
        setExcludedCategoryIds(JSON.parse(searchParams.get("exclude") ?? "[]"))
        setSearch(searchParams.get("search") ?? "")
    }, [searchParams])

    return {
        includedCategoryIds,
        excludedCategoryIds,
        search
    }
}
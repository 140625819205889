import React from 'react';
import Prompt from "../../components/Prompt/Prompt";
import {useParams} from "react-router-dom";

import "./ViewPrompt.scss"
import AnswerList from "../../components/AnswerList/AnswerList";
import PromptComments from "../../components/Prompt/PromptComments/PromptComments";
import {FaRobot} from "react-icons/fa";

function ViewPrompt() {
    const {id} = useParams()
    const prompt_id = parseInt(id ?? '-1')

    return (
        <div className={'ViewPrompt'}>
            <div className={'ViewPrompt-prompt'}>
                <h4>Prompt</h4>
                <Prompt prompt_id={prompt_id} className={'ViewPrompt-prompt-content'}/>
                <PromptComments prompt_id={prompt_id} />
            </div>
            <div className={'ViewPrompt-answers'}>
                <h4><FaRobot/>&emsp; Antworten der LLMs</h4>
                <AnswerList prompt_id={prompt_id}/>
            </div>
        </div>
    )
}

export default ViewPrompt;
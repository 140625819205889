import React, {ChangeEventHandler, HTMLAttributes, useEffect, useState} from 'react';

import "./EditSinglePlaceholder.scss"
import {Form} from "react-bootstrap";

interface EditSinglePlaceholderProps extends HTMLAttributes<HTMLTableRowElement> {
    placeholderKey: string,
    selected: boolean,
    onChangeSelected: (selected: boolean, key: string) => void,
    onChangeDescription?: (description: string, key: string) => void,
}

/**
 * This component allows the manipulation/creation of a single placeholder.
 * It must have a key (that is not editable).
 * It has a switch to allow the selection/deselection of this placeholder.
 * It has a text input to input the description of the placeholder.
 *
 * It is rendered as a table row with three columns:
 *      1. the switch to (de)select this placeholder
 *      2. the key of the placeholder
 *      3. the description
 *
 * @param placeholderKey the key of this specific placeholder. NOT EDITABLE!
 * @param selected whether this single placeholder is selected. It must be maintained via the onChangeSelected in the parent component.
 * @param onChangeSelected This function is called whenever the switch changes it positions. It is called with two parameters:
 *      1. (boolean): the new state of the switch
 *      2. (string): the current key of this placeholder
 * @param onChangeDescription This function is called whenever the description is updated. It is called with two parameters:
 *      1. (string): the new description
 *      2. (string): the current key of this placeholder
 * @param props other props that are directly passed to the enclosing tr tag.
 * @constructor
 */
function EditSinglePlaceholder({
                                   placeholderKey,
                                   selected,
                                   onChangeSelected,
                                   onChangeDescription,
                                   ...props
                               }: EditSinglePlaceholderProps) {
    const [description, setDescription] = useState<string>("")

    useEffect(() => {
        if (onChangeDescription !== undefined) {
            onChangeDescription(description, placeholderKey)
        }
    }, [description, placeholderKey, onChangeDescription])

    const handleDescriptionChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> = (event) => {
        setDescription(event.target.value);
    }

    return (
        <tr {...props}>
            <td className={'edit-single-placeholder-switch'}>
                <Form.Check
                    type={'switch'}
                    id={'enable-placeholder'}
                    checked={selected}
                    onChange={() => onChangeSelected(!selected, placeholderKey)}
                    aria-label={'Platzhalter aktivieren/deaktivieren'}
                />
            </td>
            <td className={'edit-single-placeholder-key ' + (selected ? 'selected' : 'unselected')}>
                {placeholderKey}
            </td>
            <td className={'edit-single-placeholder-description ' + (selected ? 'selected' : 'unselected')}>
                <Form.Control
                    id={'description'}
                    placeholder={'Kurze Beschreibung des Platzhalters...'}
                    disabled={!selected}
                    value={description}
                    onChange={handleDescriptionChange}
                />
            </td>
        </tr>
    )
}

export default EditSinglePlaceholder;
import React, {HTMLAttributes} from 'react';
import './CategoryOverviewPage.scss'

import CategoryEditableList from "../../../components/Category/CategoryEditableList/CategoryEditableList";

interface CategoryOverviewPageProps extends HTMLAttributes<HTMLDivElement> {

}

function CategoryOverviewPage({...props}: CategoryOverviewPageProps) {

    return (
        <div className={'CategoryOverviewPage'} {...props}>
            <h4 className={'CategoryOverviewPage-heading'}>Kategorien:</h4>
            <CategoryEditableList/>
        </div>
    )
}

export default CategoryOverviewPage;
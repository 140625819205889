import "./ViewRating.scss"
import React, {HTMLAttributes, useEffect, useState} from 'react';

interface RatingProps extends HTMLAttributes<HTMLDivElement> {
    avgRating: number
    numRatings: number
    numStars?: number
}

function ViewRating({avgRating, numRatings, numStars = 5, ...props}: RatingProps) {
    const [fillWidth, setFillWidth] = useState<number>(0)

    useEffect(() => {
        setFillWidth(avgRating / numStars)
    }, [avgRating, numStars])

    return (
        <div {...props}
             className={'rating'}
        >
            {numRatings > 0 && <span aria-hidden={true}>{avgRating.toFixed(1)}</span>}
            <div className={'rating-stars unselectable'}
                 aria-label={`${avgRating.toFixed(1)} Sterne`}
            >
                <div className={'rating-empty-star'}
                     aria-hidden={true}
                >
                    {
                        Array.from(Array(numStars).keys()).map(starNum => {
                            return <span key={starNum}>☆</span>
                        })
                    }
                </div>
                <div className={'rating-full-star-overlay'}
                     aria-hidden={true}
                     style={{width: `${fillWidth * 100}%`}}
                >
                    {
                        Array.from(Array(numStars).keys()).map(starNum => {
                            return <span key={starNum}>★</span>
                        })
                    }
                </div>
            </div>
            <span aria-label={`${numRatings} Bewertungen`}>({numRatings})</span>
        </div>
    )
}

export default ViewRating;
import React, {HTMLAttributes, useEffect, useState} from 'react';
import {OverlayTrigger, Tooltip} from "react-bootstrap";

interface TimestampProps extends HTMLAttributes<HTMLDivElement> {
    timestamp: Date,
    timestampTextTemplate?: string,
}

/**
 * This component renders a text containing a timestamp.
 * You can provide a text template containing the placeholder `{{TIMESTAMP}}`. This will be replaced with a correctly
 * formatted, human-readable version of the passed timestamp.
 *
 * When hovering over the timestamp, a more detailed representation of the time will be shown as overlay.
 *
 * @param timestamp the time that should be visualized
 * @param timestampTextTemplate a textual template that contains the placeholder `{{TIMESTAMP}}`. This template will
 *      be shown after replacing the placeholder with the passed time.
 * @param props other properties that are passed directly to the top-level div element.
 * @constructor
 */
function Timestamp({timestamp, timestampTextTemplate = "{{TIMESTAMP}}", ...props}: TimestampProps) {
    const [expandedTimestampTemplate, setExpandedTimestampTemplate] = useState<string>('')

    useEffect(() => {
        setExpandedTimestampTemplate(timestampTextTemplate.replace('{{TIMESTAMP}}', timestamp.toLocaleDateString()))
    }, [timestampTextTemplate, timestamp])

    const renderTimestampTooltip = (props: any) => (
        <Tooltip id={'timestamp-tooltip'} {...props} className={"comment-timestamp-tooltip"}>
            {timestamp.toLocaleString()}
        </Tooltip>
    )

    return (
        <div {...props}>
            <OverlayTrigger
                delay={{show: 150, hide: 300}}
                overlay={renderTimestampTooltip}
                placement={"bottom"}
            >
                {
                    ({ref, ...triggerHandler}) => (
                        <div className={'timestamp'} ref={ref} {...triggerHandler}>
                            {expandedTimestampTemplate}
                        </div>
                    )
                }
            </OverlayTrigger>
        </div>
    )
}

export default Timestamp;
import "./PromptViewCreateRating.scss"
import React, {HTMLAttributes, useEffect, useState} from 'react';
import {FaRegFrown, FaRegMeh, FaRegSmile} from "react-icons/fa";
import {PromptRatingEnum, PromptVotingType} from "../../../types/Prompt";
import {useAuth} from "react-oidc-context";
import {useDatabase} from "../../../connectors/useDatabase";

interface PromptViewCreateRatingProps extends HTMLAttributes<HTMLDivElement> {
    prompt_id: number,
    onRatingSubmitted?: () => void
}

function PromptViewCreateRating({prompt_id, onRatingSubmitted, ...props}: PromptViewCreateRatingProps) {
    const [currentUserPromptVoting, setCurrentUserPromptVoting] = useState<PromptVotingType | null>(null)
    const auth = useAuth()
    const db = useDatabase(auth.user?.access_token ?? '')

    useEffect(() => {
        if (auth.user?.profile.preferred_username !== undefined) {
            db.getPromptVotingOfUser(prompt_id, auth.user?.profile.preferred_username).then(setCurrentUserPromptVoting)
        }
    }, [auth.user, db, prompt_id])

    const handlePromptVotingFactory = (handledRating: PromptRatingEnum) => {
        return () => {
            db.createOrUpdatePromptVoting(
                prompt_id,
                auth.user?.profile.preferred_username ?? '',
                handledRating
            ).then((prompt_voting) => {
                setCurrentUserPromptVoting(prompt_voting)
                if (onRatingSubmitted !== undefined) {
                    onRatingSubmitted()
                }
            })
        }
    }

    const getRatingButtonClassName = (representedRating: PromptRatingEnum) => {
        if (currentUserPromptVoting === null) {
            return ""
        }
        if (currentUserPromptVoting.rating === representedRating) {
            return "selected"
        }
        return "unselected"
    }

    return (
        <div {...props} tabIndex={undefined} className={'prompt-view-create-rating ' + (props.className ?? '')}>
            <h6 className={'prompt-view-create-rating-label'}>Bewerte diesen Prompt:</h6>
            <div className={'prompt-view-create-rating-buttons'}>
                <button className={'button-no-color'}
                        onClick={handlePromptVotingFactory(PromptRatingEnum.GOOD)}
                        tabIndex={props.tabIndex}
                >
                    <FaRegSmile aria-label={'gut'}
                                className={`rating-good ${getRatingButtonClassName(PromptRatingEnum.GOOD)}`}
                                role={'button'}
                    />
                </button>
                <button className={'button-no-color'}
                        onClick={handlePromptVotingFactory(PromptRatingEnum.OK)}
                        tabIndex={props.tabIndex}
                >
                    <FaRegMeh aria-label={'mittel'}
                              className={`rating-ok ${getRatingButtonClassName(PromptRatingEnum.OK)}`}
                              role={'button'}
                    />
                </button>
                <button className={'button-no-color'}
                        onClick={handlePromptVotingFactory(PromptRatingEnum.BAD)}
                        tabIndex={props.tabIndex}
                >
                    <FaRegFrown aria-label={'schlecht'}
                                className={`rating-bad ${getRatingButtonClassName(PromptRatingEnum.BAD)}`}
                                onClick={handlePromptVotingFactory(PromptRatingEnum.BAD)}
                    />
                </button>
            </div>
        </div>
    )
}

export default PromptViewCreateRating;
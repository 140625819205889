import React, {ChangeEventHandler, FormEvent, useCallback, useEffect, useState} from 'react';
import {ButtonGroup, Form} from "react-bootstrap";
import './CreatePrompt.scss'
import {CategoryType} from "../../../types/CategoryType";
import {useDatabase} from "../../../connectors/useDatabase";
import {useAuth} from "react-oidc-context";
import LoadingButton from "../../LoadingButton/LoadingButton";
import CategorySelect from "../../Category/CategorySelect/CategorySelect";
import {TextPlaceholder} from "../../../types/TextPlaceholder";
import EditPlaceholders from "../EditPlaceholders/EditPlaceholders";

function CreatePrompt() {
    const [validated, setValidated] = useState<boolean>(false);
    const [prompt, setPrompt] = useState("");
    const [placeholderKeys, setPlaceholderKeys] = useState<string[]>([]);
    const [selectedPlaceholders, setSelectedPlaceholders] = useState<TextPlaceholder[]>([])
    const [description, setDescription] = useState("");
    const [selectedCategoryIds, setSelectedCategoryIds] = useState<number[]>([]);
    const [isLoading, setLoading] = useState(false);

    const [categories, setCategories] = useState<CategoryType[]>([]);
    const auth = useAuth();
    const db = useDatabase(auth.user?.access_token ?? '');

    useEffect(() => {
        db.getAllCategories().then(setCategories)
    }, [db])

    useEffect(() => {
        setPlaceholderKeys(TextPlaceholder.findPlaceholderKeys(prompt));
    }, [prompt])

    const handleFormChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> = (event) => {
        switch (event.target.id) {
            case "prompt":
                setPrompt(event.target.value);
                break;
            case "description":
                setDescription(event.target.value);
                break;
        }
    }

    const handleReset = () => {
        setPrompt('');
        setDescription('');
        setSelectedCategoryIds([]);
        setValidated(false);
    }

    const handleSubmit = useCallback((event: FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            return
        }

        event.preventDefault();

        const username = auth.user?.profile.preferred_username || ''
        setLoading(true)
        db.createPrompt(prompt, description, username, selectedCategoryIds, selectedPlaceholders).then(() => {
            setLoading(false)
            handleReset();
        })

        setValidated(true)
    }, [db, auth.user, prompt, description, selectedCategoryIds, selectedPlaceholders])

    return (
        <Form noValidate validated={validated} className={'create-prompt-container'} onSubmit={handleSubmit}>
            <Form.Group className={'create-prompt-form-group'}>
                <Form.Label><h5>Prompt:</h5></Form.Label>
                <Form.Control required
                              id={'prompt'} // used in the handleFormChange method
                              as="textarea"
                              rows={10}
                              value={prompt}
                              onChange={handleFormChange}
                              placeholder={'Der gesamte Prompt als Input für ein LLM...'}/>
                <Form.Control.Feedback type="invalid">
                    Der Prompt darf nicht leer sein.
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className={'create-prompt-form-group'}>
                <Form.Label>
                    <h5>Kurze Beschreibung/Zusammenfassung des Prompts:</h5>
                </Form.Label>
                <Form.Control required
                              id={'description'} // used in the handleFormChange method
                              type={'input'}
                              value={description}
                              onChange={handleFormChange}
                              placeholder={'Ein Satz über den Prompt. Wird in der Liste aller Prompts zur besseren Übersicht angezeigt.'}/>
                <Form.Control.Feedback type="invalid">
                    Die Beschreibung darf nicht leer sein.
                </Form.Control.Feedback>
            </Form.Group>

            <div className={'text-separator'}/>

            <Form.Group className={'create-prompt-form-group'}>
                <Form.Label>
                    <h5>Kategorien:</h5>
                </Form.Label>
                <CategorySelect availableCategories={categories} selectedCategoryIds={selectedCategoryIds}
                                setSelectedCategoryIds={setSelectedCategoryIds}/>
            </Form.Group>

            <div className={'text-separator'}/>

            <EditPlaceholders placeholderKeys={placeholderKeys} onChangeSelectedPlaceholders={setSelectedPlaceholders} />

            <div className={'text-separator'}/>

            <ButtonGroup>
                <LoadingButton variant="primary" type="submit" isLoading={isLoading}>
                    Speichern
                </LoadingButton>
            </ButtonGroup>
        </Form>
    )
}

export default CreatePrompt;
import "./TextPlaceholderView.scss"
import React, {useContext, useState} from 'react';
import {TextPlaceholder} from "../../types/TextPlaceholder";
import Button from "react-bootstrap/Button";
import {PlaceholderSubstitutionCtx} from "../../context/PlaceholderSubstitutionCtx/PlaceholderSubstitutionCtx";
import AutoResizingTextArea from "../AutoResizingTextArea/AutoResizingTextArea";

interface PlaceholderProps {
    placeholder: TextPlaceholder,
    editable?: boolean
}

function TextPlaceholderView({placeholder, editable}: PlaceholderProps) {
    const [editing, setEditing] = useState<boolean>(false)
    const {substitutions, setSubstitution} = useContext(PlaceholderSubstitutionCtx)

    const placeholderEditable = editable ?? true

    function enterEditingMode() {
        setEditing(true)
    }

    function exitEditingMode() {
        setEditing(false)
    }

    const substitutionDefined = substitutions[placeholder.key] !== undefined && substitutions[placeholder.key] !== ""
    if (placeholderEditable && (editing || substitutionDefined)) {
        // NOTE: we need the enclosing div, otherwise the text of the editable div remains somehow even if editing == false
        return <div>
            <span className={'label'}>{placeholder.key}</span>
            <AutoResizingTextArea
                value={substitutions[placeholder.key]}
                onChangeValue={(newValue) => setSubstitution(placeholder.key, newValue)}
                onBlur={exitEditingMode}
            />
        </div>
    }

    if (!placeholderEditable && substitutionDefined) {
        return <div>
            <span className={'label'}>{placeholder.key}</span>
            <div className={'text-placeholder-view-uneditable-placeholder'}>
                {substitutions[placeholder.key]}
            </div>
        </div>
    }

    return (
        <Button className={'button-no-color inline-button text-placeholder clickable'} onClick={enterEditingMode}
                aria-label={'Platzhalter befüllen'}>
            <code>{placeholder.getTemplateString()}</code>
        </Button>
    )
}

export default TextPlaceholderView;
import "./PromptOverview.scss"
import React from 'react';
import PromptList from "../../components/PromptList/PromptList";
import Pagination from "../../components/Pagination/Pagination";

function PromptOverview() {

    return (
        <div className={'PromptOverview'}>
            <Pagination>
                <PromptList/>
            </Pagination>
        </div>
    )
}

export default PromptOverview;
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './jun-styling.scss';
import App from './App';
import {AuthProvider} from "react-oidc-context";
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import PromptOverview from "./pages/PromptOverview/PromptOverview";
import Home from "./pages/Home/Home";
import ViewPrompt from "./pages/ViewPrompt/ViewPrompt";
import CreatePromptPage from "./pages/CreatePromptPage/CreatePromptPage";
import CreateCategoryPage from "./pages/Category/CreateCategoryPage/CreateCategoryPage";
import CategoryOverviewPage from "./pages/Category/CategoryOverviewPage/CategoryOverviewPage";
import CreateLLMPage from "./pages/LLM/CreateLLMPage/CreateLLMPage";
import LLMOverviewPage from "./pages/LLM/LLMOverviewPage/LLMOverviewPage";


const oidcConfig = {
    authority: `${process.env.REACT_APP_KEYCLOAK_HOST}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}`,
    client_id: `${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}`,
    redirect_uri: `${process.env.REACT_APP_KEYCLOAK_REDIRECT_URI}`
};

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        children: [
            {
                path: "/",
                element: <Home/>
            },
            {
                path: "/prompts",
                element: <PromptOverview/>
            },
            {
                path: "/prompts/add",
                element: <CreatePromptPage/>
            },
            {
                path: "/prompt/:id",
                element: <ViewPrompt/>
            },
            {
                path: "/categories",
                element: <CategoryOverviewPage/>
            },
            {
                path: "/category/add",
                element: <CreateCategoryPage/>
            },
            {
                path: "/llms",
                element: <LLMOverviewPage/>
            },
            {
                path: "/llm/add",
                element: <CreateLLMPage/>
            }
        ]
    }
])

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <AuthProvider {...oidcConfig}>
            <RouterProvider router={router}/>
        </AuthProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, {HTMLAttributes, useEffect, useState} from 'react';
import {CategoryType} from "../../../types/CategoryType";

import './CategoryLabel.scss'
import {calculateContrast, Color, colorToRGBString, parseRGBColorCode} from "../../../functions/calculateContrast";

interface CategoryLabelProps extends HTMLAttributes<HTMLDivElement>{
    category: CategoryType,
    onClickCategory?: (category: CategoryType) => void
}

const LIGHT_COLOR: Color = {red: 255, green: 255, blue: 255}
const DARK_COLOR: Color = {red: 0, green: 0, blue: 0}

function CategoryLabel({category, onClickCategory, ...props}: CategoryLabelProps) {
    const [textColor, setTextColor] = useState<Color>(LIGHT_COLOR)
    const [isClickable, setIsClickable] = useState<boolean>(false)

    useEffect(() => {
        const category_color = parseRGBColorCode(category.rgb_color_code)
        if (calculateContrast(LIGHT_COLOR, category_color) > calculateContrast(DARK_COLOR, category_color)) {
            setTextColor(LIGHT_COLOR)
        } else {
            setTextColor(DARK_COLOR)
        }
    }, [category.rgb_color_code])

    useEffect(() => {
        if (onClickCategory === undefined) {
            setIsClickable(false)
        } else {
            setIsClickable(true)
        }
    }, [onClickCategory])

    const handleClick = () => {
        if (onClickCategory !== undefined) {
            onClickCategory(category)
        }
    }


    return (
        <div {...props}
             className={`category-label ${isClickable && "clickable"} ${props.className}`}
             style={{
                 backgroundColor: category.rgb_color_code,
                 color: colorToRGBString(textColor),
                 ...props.style
             }}
             onClick={handleClick}>
            {category.name}
        </div>
    )
}

export default CategoryLabel;
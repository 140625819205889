export class TextPlaceholder {
    key: string;
    description: string;

    constructor(key: string, description: string) {
        this.key = key;
        this.description = description;
    }

    /**
     * This function returns the placeholder string as it should appear in a template string that uses this placeholder
     */
    getTemplateString() {
        return "{{" + this.key + "}}"
    }

    static findPlaceholders(text: string): TextPlaceholder[] {
        let placeholderKeys = this.findPlaceholderKeys(text)
        return placeholderKeys.map(key => new TextPlaceholder(key, ""))
    }

    static findPlaceholderKeys(text: string): string[] {
        let placeholderRegex = /\{\{(?<key>[^\r\n{}]*)}}/g

        let placeholderKeys: string[] = []

        const matches = text.matchAll(placeholderRegex)
        let next = matches.next()
        while (!next.done) {
            const match = next.value
            if (match.groups !== undefined) {
                const foundKey = match.groups["key"]
                if (!placeholderKeys.includes(foundKey)) {
                    placeholderKeys.push(foundKey)
                }
            }
            next = matches.next()
        }

        return placeholderKeys
    }
}

export interface TextPlaceholderSubstitution {
    [key: string]: string
}

/**
 * This type is used when interacting with the database. There each placeholder has an individual id, to allow having
 * placeholders with the same key in different prompts.
 */
export interface PlaceholderWithId {
    id: number,
    placeholder: TextPlaceholder
}

import "./CreateLLMPage.scss"
import React, {HTMLAttributes} from 'react';
import LLMCreate from "../../../components/llm/LLMCreate/LLMCreate";

interface CreateLLMPageProps extends HTMLAttributes<HTMLDivElement> {

}

function CreateLLMPage({...props}: CreateLLMPageProps) {
    return (
        <div {...props} className={'CreateLLMPage ' + props.className ?? ''}>
            <h5 className={'CreateLLMPage-heading default-heading'}>LLM Hinzufügen:</h5>
            <LLMCreate/>
            <h5 className={'CreateLLMPage-heading default-heading'}>Bereits erstellte LLMs:</h5>
        </div>
    )
}

export default CreateLLMPage;
import React, {HTMLAttributes, useEffect, useState} from 'react';
import {TextPlaceholder} from "../../../types/TextPlaceholder";
import EditSinglePlaceholder from "./EditSinglePlaceholder/EditSinglePlaceholder";
import _ from "lodash";

import "./EditPlaceholders.scss"

interface EditPlaceholdersProps extends HTMLAttributes<HTMLDivElement> {
    placeholderKeys: string[],
    onChangeSelectedPlaceholders?: (placeholders: TextPlaceholder[]) => void,
    defaultDeselectedPlaceholderKeys?: string[]
}

function EditPlaceholders({
                              placeholderKeys,
                              onChangeSelectedPlaceholders,
                              defaultDeselectedPlaceholderKeys,
                              ...props
                          }: EditPlaceholdersProps) {
    const [deselectedPlaceholderKeys, setDeselectedPlaceholderKeys] = useState<string[]>([])
    const [descriptions, setDescriptions] = useState<{ [key: string]: string }>({})

    useEffect(() => {
        if (onChangeSelectedPlaceholders !== undefined) {
            const filteredPlaceholderKeys = placeholderKeys.filter(key => !deselectedPlaceholderKeys.includes(key))
            const placeholders = filteredPlaceholderKeys.map(key => new TextPlaceholder(key, descriptions[key]))
            onChangeSelectedPlaceholders(placeholders)
        }
    }, [deselectedPlaceholderKeys, descriptions, onChangeSelectedPlaceholders, placeholderKeys])

    useEffect(() => {
        setDeselectedPlaceholderKeys((deselectedPlaceholderKeys) => _.union(deselectedPlaceholderKeys, defaultDeselectedPlaceholderKeys))
    }, [defaultDeselectedPlaceholderKeys])

    const handleChangeSelected = (selected: boolean, key: string) => {
        if (selected) {
            setDeselectedPlaceholderKeys(deselectedPlaceholderKeys.filter(selectedKey => selectedKey !== key))
        }
        if (!selected && !deselectedPlaceholderKeys.includes(key)) {
            let newDeselectedPlaceholderKeys = _.cloneDeep(deselectedPlaceholderKeys)
            newDeselectedPlaceholderKeys.push(key)
            setDeselectedPlaceholderKeys(newDeselectedPlaceholderKeys)
        }
    }

    const handleChangeDescription = (description: string, key: string) => {
        let newDescriptions = _.cloneDeep(descriptions)
        newDescriptions[key] = description
        setDescriptions(newDescriptions)
    }

    return (
        <div {...props}>
            <h5>Platzhalter:</h5>
            <p>
                Im Prompt können Platzhalter verwendet werden, die individuell bei jeder Nutzung des Prompts ersetzt
                werden müssen.
                Bei einem Prompt zur Analyse eines Vertrags, wäre der Vertrag selber z.B. ein Platzhalter.
                Für jeden automatisch gefundenen Platzhalter kann entscheiden werden, ob dieser auch tatsächlich als
                solcher gespeichert werden soll,
                oder er fehlerhafter Weise als Platzhalter ausgewählt wurde.
            </p>
            <p>
                Platzhalter werden automatisch im obigen Text des Prompts erkannt, wenn sie wie folgt formuliert
                werden: <code>{"{{NAME_DES_PLATZHALTERS}}"}</code>
                <br/>Der Name innerhalb der geschweiften Klammern kann dabei beliebig gewählt werden.
                Leerzeichen sind möglich, aber nicht empfohlen.
            </p>
            {
                placeholderKeys.length > 0 &&
                <table>
                    <thead>
                    <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Beschreibung</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        placeholderKeys.map(key =>
                            <EditSinglePlaceholder
                                placeholderKey={key}
                                key={key}
                                selected={!deselectedPlaceholderKeys.includes(key)}
                                onChangeSelected={handleChangeSelected}
                                onChangeDescription={handleChangeDescription}
                            />)
                    }
                    </tbody>
                </table>
            }

        </div>
    )
}

export default EditPlaceholders;
import './Prompt.scss';
import React, {useState} from 'react';
import {Button} from "react-bootstrap";
import {FaEdit, FaTrash} from "react-icons/fa";
import EditPrompt from "./EditPrompt/EditPrompt";
import PromptView from "./PromptView/PromptView";
import DeleteModal from "../DeleteModal/DeleteModal";
import {useAuth} from "react-oidc-context";
import {useDatabase} from "../../connectors/useDatabase";
import {useNavigate} from "react-router-dom";

interface PromptProps extends React.HTMLAttributes<HTMLElement> {
    prompt_id: number,
}


/**
 * This component renders a prompt.
 * It takes the id and fetches the data for the prompt and its categories from the database.
 *
 * It shows edit buttons for the prompt
 *
 * @param prompt_id the id of the prompt
 * @param props other properties that are directly passed to the top-level Card component
 * @constructor
 */
function Prompt({
                    prompt_id,
                    ...props
                }: PromptProps) {
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const navigate = useNavigate()

    const auth = useAuth()
    const db = useDatabase(auth.user?.access_token ?? '')

    const handleDelete = () => {
        db.deletePrompt(prompt_id).then(() => {
                setShowDeleteModal(true)
                navigate("/prompts")
            }
        )
    }

    if (isEditing) {
        return <div {...props} className={'prompt'}>
            <EditPrompt prompt_id={prompt_id}
                        onCancel={() => setIsEditing(false)}
                        onSuccessfulSubmit={() => {
                            setIsEditing(false)
                        }}
            />
        </div>
    }

    return (
        <div  {...props} tabIndex={undefined} className={'prompt'}>
            <PromptView prompt_id={prompt_id}
                        expandable={false}
                        showLink={false}
                        showPromptRatingButtons={true}
                        tabIndex={props.tabIndex}
            />
            <div className={'prompt-button-group'}>
                <Button variant={'secondary'}
                        onClick={() => setIsEditing(true)}
                >
                    <FaEdit aria-hidden={true}/> Bearbeiten
                </Button>
                <Button variant={'danger'}
                        onClick={() => setShowDeleteModal(true)}
                >
                    <FaTrash aria-hidden={true}/> Löschen
                </Button>
                <DeleteModal title={"Prompt löschen"}
                             onHide={() => setShowDeleteModal(false)}
                             onConfirmDelete={handleDelete}
                             show={showDeleteModal}
                >
                    <p>
                        Soll der Prompt wirklich gelöscht werden?
                    </p>
                    <p>
                        Im Normalfall kann das nicht rückgängig gemacht werden. Lediglich über eine Mail an den
                        IT-Support (<a href={'mailto:it@kanzlei-jun.de'}>it@kanzlei-jun.de</a>) kann das Löschen unter
                        Umständen rückgängig gemacht werden.
                    </p>
                </DeleteModal>
            </div>
        </div>
    )
}

export default Prompt;
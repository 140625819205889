import React, {useContext, useEffect, useRef, useState} from 'react';
import './PromptList.scss'

import {useAuth} from "react-oidc-context";
import {useDatabase} from "../../connectors/useDatabase";
import PromptListFilter from "./PromptListFilter/PromptListFilter";
import usePromptListFilterData from "./PromptListFilter/usePromptListFilterData";
import PromptView from "../Prompt/PromptView/PromptView";
import {PaginationCtx} from "../../context/PaginationCtx/PaginationCtx";

function PromptList() {
    const [promptIds, setPromptIds] = useState<number[]>([])
    const abortController = useRef<AbortController>(new AbortController())
    const {includedCategoryIds, excludedCategoryIds, search} = usePromptListFilterData()
    const auth = useAuth();
    const db = useDatabase(auth.user?.access_token ?? '')

    const pagination = useContext(PaginationCtx)

    useEffect(() => {
        abortController.current.abort()
        abortController.current = new AbortController()
        db.getAllPromptIds(includedCategoryIds, excludedCategoryIds, search, abortController.current, pagination.range)
            .then(([ids, totalNumIds]) => {
                setPromptIds(ids)
                pagination.setTotalNumEntries(totalNumIds)
            })
            .catch(error => {
                if (error.code === "ERR_CANCELED") {
                    // this request was canceled => do nothing
                    return
                }
                throw error // other error
            })
    }, [db, includedCategoryIds, excludedCategoryIds, search, pagination])

    return (
        <div>
            <PromptListFilter includedCategoryIds={includedCategoryIds} excludedCategoryIds={excludedCategoryIds}/>
            <div className={'prompt-list'}>
                {promptIds.map(prompt_id => <PromptView prompt_id={prompt_id}
                                                        expandable={true}
                                                        showLink={true}
                                                        key={prompt_id}/>)}
            </div>
        </div>
    )
}

export default PromptList;
import React, {ChangeEventHandler, FormEvent, HTMLAttributes, useState} from 'react';
import {Form} from "react-bootstrap";
import CategoryLabel from "../CategoryLabel/CategoryLabel";
import _ from "lodash";
import {useAuth} from "react-oidc-context";
import {useDatabase} from "../../../connectors/useDatabase";
import LoadingButton from "../../LoadingButton/LoadingButton";

import "./CategoryCreate.scss"

interface CategoryCreateProps extends HTMLAttributes<HTMLDivElement>{
}


const DEFAULT_COLORS: string[] = [
    "#7e1718",
    "#595959",
    "#df87d9",
    "#3654e5",
    "#0cb60c",
    "#ffc828"
]

function CategoryCreate({...props}: CategoryCreateProps) {
    const [category, setCategory] = useState<string>("")
    const [defaultColor] = useState<string>(_.sample(DEFAULT_COLORS) ?? DEFAULT_COLORS[0])
    const [color, setColor] = useState<string>(defaultColor)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [duplicateError, setDuplicateError] = useState<boolean>(false)

    const auth = useAuth()
    const db = useDatabase(auth.user?.access_token ?? "")

    const handleCategoryChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setCategory(event.target.value)
        if (duplicateError) {
            setDuplicateError(false)
        }
    }

    const handleColorChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setColor(event.target.value)
    }

    const handleReset = () => {
        setColor(_.sample(DEFAULT_COLORS) ?? DEFAULT_COLORS[0])
        setCategory("")
        setDuplicateError(false)
    }

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
            return
        }

        event.preventDefault();

        setLoading(true)
        db.createCategory(category, color).then(() => {
            setLoading(false)
            handleReset()
        }).catch(error => {
            if (error.isConflictError) {
                setLoading(false)
                setDuplicateError(true)
            }
        })
    }

    return (
        <div {...props} className={'category-create'}>
            <div className={'category-create-body'}>
                <Form noValidate
                      className={'category-create-input'}
                      onSubmit={handleSubmit}
                >
                    <div className={"category-create-input-fields"}>
                        <Form.Group style={{width: '100%'}}>
                            <Form.Control
                                required={true}
                                placeholder={"Name der neuen Kategorie"}
                                aria-label={"Name der neuen Kategorie"}
                                onChange={handleCategoryChange}
                                value={category}
                                isValid={category.length > 0 && !duplicateError}
                                isInvalid={duplicateError}
                            />
                            <Form.Control.Feedback type="invalid">
                                Kategorie mit diesem Namen existiert bereits.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Control
                            required={false}
                            type="color"
                            id="categoryColor"
                            value={color}
                            title="Farbe der Kategorie"
                            onChange={handleColorChange}
                        />
                    </div>
                    <LoadingButton type={'submit'} isLoading={isLoading} disabled={category.length === 0}>
                        Speichern
                    </LoadingButton>
                </Form>
                <div className={'category-create-preview'}>
                    <CategoryLabel
                        category={{
                            id: -1,
                            name: category || "Vorschau",
                            rgb_color_code: color
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default CategoryCreate;
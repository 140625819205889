import "./LLMEditableList.scss"
import React, {HTMLAttributes, useEffect, useState} from 'react';
import {useAuth} from "react-oidc-context";
import {useDatabase} from "../../../connectors/useDatabase";
import {LLMType} from "../../../types/LLMTypes";
import Toolbar from "../../Toolbar/Toolbar";

interface LLMEditableListProps extends HTMLAttributes<HTMLDivElement> {

}

function LLMEditableList({...props}: LLMEditableListProps) {
    const [LLMs, setLLMs] = useState<LLMType[]>([])
    const auth = useAuth()
    const db = useDatabase(auth.user?.access_token ?? '')

    useEffect(() => {
        db.getAllLLMs().then(setLLMs)
    }, [auth, db])

    return (
        <div {...props}>
            <table className={'show-separator'}>
                <tbody>
                {LLMs.map(llm => {
                    return <tr>
                        <td>
                            <Toolbar<LLMType> referencedObject={llm}/>
                        </td>
                        <td className={'llm-editable-list-llm-name'}>
                            {llm.name}
                        </td>
                    </tr>
                })}
                </tbody>
            </table>
        </div>
    )
}

export default LLMEditableList;
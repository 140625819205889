import "./LLMOverviewPage.scss"
import React, {HTMLAttributes} from 'react';
import LLMEditableList from "../../../components/llm/LLMEditableList/LLMEditableList";

interface LLMOverviewPageProps extends HTMLAttributes<HTMLDivElement> {

}

function LLMOverviewPage({...props}: LLMOverviewPageProps) {
    return (
        <div {...props} className={'LLMOverviewPage'}>
            <h4 className={'default-heading'}>LLMs:</h4>
            <LLMEditableList/>
        </div>
    )
}

export default LLMOverviewPage;
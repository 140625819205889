import "./Pagination.scss"
import React, {HTMLAttributes, useEffect, useState} from 'react';
import {PaginationCtx} from "../../context/PaginationCtx/PaginationCtx";
import Button from "react-bootstrap/Button";
import {FaAngleLeft, FaAngleRight, FaAnglesLeft, FaAnglesRight} from "react-icons/fa6";
import {ButtonGroup, Dropdown} from "react-bootstrap";

interface PaginationProps extends HTMLAttributes<HTMLDivElement> {

}

const DEFAULT_ENTRIES_PER_PAGE = 10;

function Pagination({children, ...props}: PaginationProps) {
    const [totalNumEntries, setTotalNumEntries] = useState<number>(0)
    const [currentPage, setCurrentPage] = useState<number>(0)
    const [entriesPerPage, setEntriesPerPage] = useState<number>(DEFAULT_ENTRIES_PER_PAGE)
    const [pageSelection, setPageSelection] = useState<number[]>([0])
    // maximal available page number
    const [maxPage, setMaxPage] = useState<number>(0)

    useEffect(() => {
        if (entriesPerPage < 0) {
            setMaxPage(0)
            return
        }

        const numPages = Math.ceil(totalNumEntries / entriesPerPage)
        setMaxPage(numPages - 1)
    }, [totalNumEntries, entriesPerPage])

    useEffect(() => {
        let minShowingPage = Math.max(0, currentPage - 2)
        if (minShowingPage + 4 > maxPage) {
            minShowingPage = Math.max(0, maxPage - 4)
        }

        let newPageSelection = []
        for (let i = 0; i < 5; i++) {
            if (minShowingPage + i > maxPage) {
                break
            }
            newPageSelection.push(minShowingPage + i)
        }
        setPageSelection(newPageSelection)
    }, [maxPage, currentPage])

    useEffect(() => {
        // when changing the entriesPerPage while on a higher page, it may arise that the current page is higher than
        // the (new) maxPage. In this case, we want to go to the (new) last page.
        if (maxPage < currentPage) {
            // Note, that we have to clip the current page at 0, as when this component is mounted, the maxPage may be -1
            setCurrentPage(Math.max(maxPage, 0))
        }
    }, [maxPage, currentPage])

    return (
        <PaginationCtx.Provider value={{
            currentPage,
            entriesPerPage,
            range: {
                offset: currentPage * entriesPerPage,
                limit: entriesPerPage < 0 ? totalNumEntries : entriesPerPage
            },
            setTotalNumEntries
        }}>
            <div {...props} className={'pagination-container ' + (props.className ?? '')}>
                {children}
                <div className={'pagination-controls'}>
                    <div className={'pagination-num-pages'}>
                        {totalNumEntries} Ergebnisse auf {maxPage + 1} {maxPage === 0 ? "Seite" : "Seiten"}
                    </div>

                    <ButtonGroup className={'pagination'}>
                        <Button variant={'light'}
                                onClick={() => setCurrentPage(0)}
                        >
                            <FaAnglesLeft aria-label={'zu erster Seite'}/>
                        </Button>
                        <Button variant={'light'}
                                onClick={() => setCurrentPage(currentPage - 1)}
                                disabled={currentPage === 0}
                        >
                            <FaAngleLeft aria-label={'vorherige Seite'}/>
                        </Button>
                        {
                            pageSelection.map(page => {
                                if (page === currentPage) {
                                    return <div className={'pagination-cur-page'} aria-label={'aktuelle Seite'}
                                                key={page}>
                                        <strong>{currentPage + 1}</strong>
                                    </div>
                                }
                                return <Button variant={'link'}
                                               onClick={() => setCurrentPage(page)}
                                               aria-label={`zu Seite ${page + 1}`}
                                               key={page}
                                >
                                    {page + 1}
                                </Button>
                            })
                        }

                        <Button variant={'light'}
                                onClick={() => setCurrentPage(currentPage + 1)}
                                disabled={currentPage === maxPage}
                        >
                            <FaAngleRight aria-label={'nächste Seite'}/>
                        </Button>
                        <Button variant={'light'}
                                onClick={() => setCurrentPage(maxPage)}
                        >
                            <FaAnglesRight aria-label={'zu letzter Seite'}/>
                        </Button>
                    </ButtonGroup>

                    <div className={'pagination-entries-per-page'}>
                        Einträge pro Seite:
                        <Dropdown onSelect={(eventKey) => setEntriesPerPage(parseInt(eventKey ?? '-1'))}>
                            <Dropdown.Toggle variant={'light'}>
                                {entriesPerPage < 0 ? "Alle" : entriesPerPage}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item eventKey={10}>10</Dropdown.Item>
                                <Dropdown.Item eventKey={20}>20</Dropdown.Item>
                                <Dropdown.Item eventKey={50}>50</Dropdown.Item>
                                <Dropdown.Item eventKey={-1}>Alle</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
        </PaginationCtx.Provider>
    )
}

export default Pagination;
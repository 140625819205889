import React, {useEffect, useState} from 'react';
import {Dropdown, DropdownButton} from "react-bootstrap";
import {FaPlus} from "react-icons/fa";
import {CategoryType} from "../../../types/CategoryType";
import CategoryList from "../CategoryList/CategoryList";

import "./CategorySelect.scss";
import {useAuth} from "react-oidc-context";
import {useDatabase} from "../../../connectors/useDatabase";

export type SetSelectedCategories = {
    (newSelectedCategories: number[]): void
}
type CategorySelectProps = {
    availableCategories?: CategoryType[]
    selectedCategoryIds: number[],
    setSelectedCategoryIds: SetSelectedCategories
}

function CategorySelect({availableCategories, selectedCategoryIds, setSelectedCategoryIds}: CategorySelectProps) {
    const [allCategories, setAllCategories] = useState<CategoryType[]>(availableCategories ?? [])
    const auth = useAuth()
    const db = useDatabase(auth.user?.access_token ?? '')

    useEffect(() => {
        if (availableCategories === undefined) {
            db.getAllCategories().then(setAllCategories)
        } else {
            setAllCategories(availableCategories)
        }
    }, [availableCategories, db])

    const onChangeCategoryState = (categoryId: number, selected: boolean) => {
        if (selected) {
            if (!selectedCategoryIds.includes(categoryId)) {
                setSelectedCategoryIds(selectedCategoryIds.concat([categoryId]))
            }
        } else {
            setSelectedCategoryIds(selectedCategoryIds.filter(id => id !== categoryId))
        }
    }

    const handleSelectCategory = (eventKey: string | null) => {
        if (eventKey === null) {
            throw new Error('Event key of category list element can not be null.')
        }
        const categoryId = parseInt(eventKey)
        if (!selectedCategoryIds.includes(categoryId)) {
            onChangeCategoryState(categoryId, true)
        }
    }

    const handleDeselectCategory = (category: CategoryType) => {
        onChangeCategoryState(category.id, false)
    }

    const showAddCategoryButton = allCategories.length > selectedCategoryIds.length

    return (
        <div className={'category-select'}>
            <CategoryList categories={allCategories.filter(category => selectedCategoryIds.includes(category.id))}
                          onClickLabel={handleDeselectCategory}/>
            <DropdownButton disabled={!showAddCategoryButton}
                            title={<FaPlus/>}
                            autoClose={'outside'}
                            size={'sm'}
                            onSelect={handleSelectCategory}>
                {allCategories
                    .filter(category => !selectedCategoryIds.includes(category.id)) // only show unselected categories
                    .map(category =>
                        <Dropdown.Item eventKey={category.id} key={category.id}>
                            {category.name}
                        </Dropdown.Item>
                    )}
            </DropdownButton>
        </div>
    )
}

export default CategorySelect;
import React from 'react';
import CreatePrompt from "../../components/Prompt/CreatePrompt/CreatePrompt";

import "./CreatePromptPage.scss"

function CreatePromptPage() {
    return (
        <div className={'CreatePromptPage'}>
            <CreatePrompt/>
        </div>
    )
}

export default CreatePromptPage;
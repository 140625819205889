import {useDatabaseConnector} from "./useDatabaseConnector";
import {FullPromptType, PromptType, PromptWithCategoriesType} from "../../types/Prompt";
import {CategoryType} from "../../types/CategoryType";
import {useCategoryEndpoints} from "./useCategoryEndpoints";
import {PlaceholderWithId, TextPlaceholder} from "../../types/TextPlaceholder";
import {usePlaceholderEndpoints} from "./usePlaceholderEndpoints";
import {useCallback, useMemo} from "react";
import {PaginationRange} from "../../context/PaginationCtx/PaginationCtx";

export const usePromptEndpoints = (token: string, abortController: AbortController | null = null) => {
    const dbConnector = useDatabaseConnector(token, abortController);
    const categoryEndpoints = useCategoryEndpoints(token, abortController);
    const placeholderEndpoints = usePlaceholderEndpoints(token, abortController);

    /**
     * This function takes a prompt returned from the database and transforms some fields to the correct types/formats.
     * It transforms both timestamps (created and last_changed) into a Date object.
     *
     * @param raw_prompt the raw returned prompt from the database.
     */
    const transformReturnedPrompt = useCallback((
        raw_prompt: any
    ): PromptType => {
        return {
            id: raw_prompt.id,
            text: raw_prompt.text,
            description: raw_prompt.description,
            created_by: raw_prompt.created_by,
            created_at: new Date(raw_prompt.created_at),
            last_changed_at: new Date(raw_prompt.last_changed_at),
            last_changed_by: raw_prompt.last_changed_by,
            version: raw_prompt.version
        }
    }, [])

    const getCategoriesOfPrompt = useCallback((
        prompt_id: number
    ): Promise<CategoryType[]> => {
        return dbConnector.get(
            "/prompt_to_category",
            {
                params: {
                    prompt_id: `eq.${prompt_id}`
                }
            }
        ).then(response => {
            const categoryIds: number[] = response.data.map(({
                                                                 category_id
                                                             }: { prompt_id: number, category_id: number }) => category_id)
            return categoryIds
        }).then((categoryIds) => {
            return categoryEndpoints.getMultipleCategories(categoryIds)
        })
    }, [dbConnector, categoryEndpoints])

    const getPlaceholdersOfPrompt = useCallback((
        prompt_id: number
    ): Promise<PlaceholderWithId[]> => {
        return dbConnector.get(
            "/prompt_to_placeholder",
            {
                params: {
                    prompt_id: `eq.${prompt_id}`
                }
            }
        ).then(response => {
            const placeholderIds: number[] = response.data.map(({
                                                                    placeholder_id
                                                                }: { prompt_id: number, placeholder_id: number }) => placeholder_id)
            return placeholderIds
        }).then((placeholderIds) => {
            return placeholderEndpoints.getMultiplePlaceholders(placeholderIds)
        })
    }, [dbConnector, placeholderEndpoints])

    const createPrompt = useCallback((
        text: string,
        description: string,
        created_by: string,
        category_ids: number[],
        placeholders: TextPlaceholder[]
    ) => {
        const created_at = new Date();
        const last_changed_at = created_at;

        return dbConnector.post(
            "/prompt",
            {
                text,
                description,
                created_by,
                created_at,
                last_changed_at,
                last_changed_by: created_by,
                deleted: false
            }, {
                headers: {
                    Prefer: "return=representation"
                }
            }
        ).then(result => {
                const created_prompt = result.data[0] as PromptType

                const data = category_ids.map(category_id => {
                    return {
                        prompt_id: created_prompt.id,
                        category_id
                    }
                })

                return dbConnector.post(
                    "/prompt_to_category",
                    data
                ).then(() => { // create the placeholders
                    return placeholderEndpoints.createAndConnectMultiplePlaceholders(created_prompt.id, placeholders)
                })
            }
        )
    }, [dbConnector, placeholderEndpoints])

    const updatePrompt = useCallback((
        id: number,
        new_text: string,
        new_description: string,
        new_last_changed_by: string,
        new_category_ids: number[],
        new_placeholders: TextPlaceholder[]
    ) => {
        const last_changed_at = new Date();

        // Step 1: update prompt
        const updatePromptPromise = dbConnector.patch(
            "/prompt",
            {
                text: new_text,
                description: new_description,
                last_changed_by: new_last_changed_by,
                last_changed_at
            },
            {
                params: {
                    id: `eq.${id}`
                }
            }
        )

        // Step 2: update the connections between this prompt and the categories
        const updateCategoriesPromise = getCategoriesOfPrompt(id).then(current_categories => {
            const current_category_ids = current_categories.map(category => category.id)
            const categoriesToDelete = current_category_ids.filter(category_id => !new_category_ids.includes(category_id))
            const categoriesToCreate = new_category_ids.filter(category_id => !current_category_ids.includes(category_id))

            const promises: Promise<any>[] = []

            // Step 2.1 delete all category connections that are not valid anymore
            if (categoriesToDelete.length > 0) {
                promises.push(
                    dbConnector.delete(
                        "/prompt_to_category",
                        {
                            params: {
                                prompt_id: `eq.${id}`,
                                category_id: `in.(${categoriesToDelete.join(",")})`
                            }
                        }
                    )
                )
            }

            // Step 2.2 add the new category connections
            categoriesToCreate.forEach(category_id => {
                promises.push(
                    dbConnector.post(
                        "/prompt_to_category",
                        {
                            prompt_id: id,
                            category_id
                        }
                    )
                )
            })

            return Promise.all(promises)
        })

        // Step 3: update the placeholders
        const createOrUpdatePlaceholdersPromise = getPlaceholdersOfPrompt(id).then(current_placeholders_with_ids => {
            // transform the array of new placeholders into an object that maps the (new) keys to the new descriptions
            let newPlaceholderDescriptions: Record<string, string> = {}
            new_placeholders.forEach(placeholder => newPlaceholderDescriptions[placeholder.key] = placeholder.description)

            // Step 3.1: update the descriptions of remaining keys and disconnect placeholders that do not remain
            const updatePlaceholdersPromises: Promise<any>[] = []
            current_placeholders_with_ids.forEach(current_placeholder_with_id => {
                const placeholder_id = current_placeholder_with_id.id
                const key = current_placeholder_with_id.placeholder.key

                if (newPlaceholderDescriptions.hasOwnProperty(key)) {
                    // placeholder with this key remains => update the description
                    updatePlaceholdersPromises.push(
                        placeholderEndpoints.updatePlaceholder(placeholder_id, key, newPlaceholderDescriptions[key])
                    )
                } else {
                    // this placeholder should be disconnected from this prompt
                    updatePlaceholdersPromises.push(
                        placeholderEndpoints.disconnectPlaceholder(id, placeholder_id, true)
                    )
                }
            })

            // Step 3.2: create missing placeholders
            const currentPlaceholderKeys = current_placeholders_with_ids.map(placeholder_with_id => placeholder_with_id.placeholder.key)
            const placeholdersToCreate = new_placeholders.filter(placeholder => !currentPlaceholderKeys.includes(placeholder.key))
            const createPlaceholdersPromise = placeholderEndpoints.createAndConnectMultiplePlaceholders(id, placeholdersToCreate)

            return Promise.all([...updatePlaceholdersPromises, createPlaceholdersPromise])
        })

        return Promise.all([updatePromptPromise, updateCategoriesPromise, createOrUpdatePlaceholdersPromise])
    }, [dbConnector, getCategoriesOfPrompt, getPlaceholdersOfPrompt, placeholderEndpoints])

    const getPromptWithoutCategories = useCallback((
        id: number
    ): Promise<PromptType> => {
        return dbConnector.get(
            "/prompt",
            {
                params: {
                    id: `eq.${id}`,
                    deleted: `eq.false`
                }
            }
        ).then(response => {
            if (response.data.length < 1) {
                throw Error(`no prompt with id '${id}' found`)
            }

            return transformReturnedPrompt(response.data[0])
        })
    }, [dbConnector, transformReturnedPrompt])

    const getPrompt = useCallback((
        id: number
    ): Promise<FullPromptType> => {
        return getPromptWithoutCategories(id)
            .then(prompt => {
                return getCategoriesOfPrompt(prompt.id).then(categories => {
                    return {prompt, categories}
                })
            })
            .then(({prompt, categories}) => {
                return getPlaceholdersOfPrompt(prompt.id).then(placeholders => {
                    return {prompt, categories, placeholders}
                })
            })
    }, [getPromptWithoutCategories, getCategoriesOfPrompt, getPlaceholdersOfPrompt])

    const getNumPrompts = useCallback((): Promise<number> => {
        return dbConnector.head(
            "/prompt",
            {
                params: {
                    deleted: `eq.false`
                },
                headers: {
                    Prefer: "count=exact"
                }
            }
        ).then(response => {
            const httpContentRange = response.headers['content-range']
            return parseInt(httpContentRange.split("/")[1])
        })
    }, [dbConnector])

    /**
     * Returns all prompt ids.
     * The prompts can be filtered by their categories (black- and/or whitelist).
     * The blacklist overwrites the whitelist.
     *
     * It returns a 2-tupel.
     *  1: array of the ids of the prompts (respecting the passed range)
     *  2: total number of prompts
     *
     * @param includeCategories Only prompts with this category are returned (whitelist)
     * @param excludeCategories Prompts that have this category are excluded from the result (blacklist)
     * @param searchText a string that is used as full text search to filter (and order) the results
     * @param abortControllerOverride an optional abort controller that handles the abortion of this request.
     *              If given, it is used instead of the AbortController passed as parameter.
     * @param range a pagination range that offsets/limits the results
     */
    const getAllPromptIds = useCallback((
        includeCategories: number[] = [],
        excludeCategories: number[] = [],
        searchText: string = "",
        abortControllerOverride: AbortController | null = null,
        range: PaginationRange = {offset: 0, limit: -1}
    ): Promise<[number[], number]> => {
        // include filter: WHERE category_ids @> ARRAY[includeCategories]
        let includeFilter = `category_ids.cs.{${includeCategories.join(',')}}`
        // exclude filter: WHERE NOT (category_ids && ARRAY[excludeCategories])
        let excludeFilter = `not.and(category_ids.ov.{${excludeCategories.join(',')}})`
        // use full text search on description and title vie the generated search_index_col
        let searchFilter = `search_index_col.wfts(german).${searchText}`
        // only non-deleted prompts
        let nonDeletedFilter = `deleted.eq.false`

        let filter;
        if (searchText === '') {
            filter = `(${includeFilter},${excludeFilter},${nonDeletedFilter})`
        } else {
            filter = `(${includeFilter},${excludeFilter},${searchFilter},${nonDeletedFilter})`
        }

        return dbConnector.get(
            "/prompt_full",
            {
                params: {
                    select: "id",
                    and: filter,
                    order: 'created_at.asc',
                    offset: range.offset,
                    limit: range.limit
                },
                headers: {
                    Prefer: "count=exact"
                },
                signal: abortControllerOverride?.signal ?? abortController?.signal
            }
        )
            .then(response => {
                const ids = response.data.map(({id}: PromptType) => id) as number[]

                const httpContentRange = response.headers['content-range']
                const totalNumResults = parseInt(httpContentRange.split("/")[1])

                return [ids, totalNumResults]
            }) // transform the returned data in a list of the prompt ids
    }, [dbConnector, abortController?.signal])

    const getAllPrompts = useCallback((): Promise<PromptWithCategoriesType[]> => {
        return getAllPromptIds()
            .then(([prompt_ids]) => {
                const getPromptPromises = prompt_ids.map(getPrompt)

                return Promise.all(getPromptPromises)
            })
    }, [getAllPromptIds, getPrompt])

    const deletePrompt = useCallback((
        prompt_id: number
    ) => {
        return dbConnector.patch(
            "/prompt",
            {
                deleted: true
            },
            {
                params: {
                    id: `eq.${prompt_id}`
                }
            }
        )
    }, [dbConnector])

    return useMemo(() => {
        return {createPrompt, updatePrompt, getPrompt, getNumPrompts, getAllPrompts, getAllPromptIds, deletePrompt}
    }, [createPrompt, updatePrompt, getPrompt, getNumPrompts, getAllPrompts, getAllPromptIds, deletePrompt])
}
import {useDatabaseConnector} from "./useDatabaseConnector";
import {
    PromptRatingEnum,
    PromptVotingResultType,
    PromptVotingType,
} from "../../types/Prompt";
import {useCallback, useMemo} from "react";

export const usePromptVotingEndpoints = (token: string, abortController: AbortController | null = null) => {
    const dbConnector = useDatabaseConnector(token, abortController);

    const createPromptVoting = useCallback((
        prompt_id: number,
        created_by: string,
        rating: PromptRatingEnum
    ): Promise<PromptVotingType> => {
        const created_at = new Date();
        const last_changed_at = created_at;

        return dbConnector.post(
            "/prompt_voting",
            {
                prompt_id,
                rating: rating.toString(),
                created_by,
                created_at,
                last_changed_at
            },
            {
                headers: {
                    Prefer: "return=representation"
                }
            }
        ).then(response => {
            if (response.data.length !== 1) {
                throw Error('Creating a Prompt returned not exactly one prompt => Creation failed or DB is in illegal state (e.g. multiple prompts with same id)')
            }
            return response.data[0]
        })
    }, [dbConnector])

    const updatePromptVoting = useCallback((
        id: number,
        new_rating: PromptRatingEnum
    ): Promise<PromptVotingType> => {
        const last_changed_at = new Date();

        return dbConnector.patch(
            "/prompt_voting",
            {
                rating: new_rating.toString(),
                last_changed_at
            },
            {
                params: {
                    id: `eq.${id}`
                },
                headers: {
                    Prefer: "return=representation"
                }
            }
        ).then(response => {
            if (response.data.length !== 1) {
                throw Error('Updating a Prompt returned not exactly one prompt => Creation failed or DB is in illegal state (e.g. multiple prompts with same id)')
            }
            return response.data[0]
        })
    }, [dbConnector])

    const createOrUpdatePromptVoting = useCallback((
        prompt_id: number,
        created_by: string,
        rating: PromptRatingEnum
    ): Promise<PromptVotingType> => {
        return dbConnector.get(
            "/prompt_voting",
            {
                params: {
                    prompt_id: `eq.${prompt_id}`,
                    created_by: `eq.${created_by}`
                }
            }
        ).then(response => {
            if (response.data.length === 0) {
                return createPromptVoting(prompt_id, created_by, rating)
            }
            // voting for this prompt-user-pair already exist => update
            return updatePromptVoting(response.data[0].id, rating)
        })
    }, [dbConnector, createPromptVoting, updatePromptVoting])

    const getPromptVotings = useCallback((
        prompt_id: number
    ): Promise<PromptVotingResultType> => {
        return dbConnector.get(
            "/prompt_voting",
            {
                params: {
                    prompt_id: `eq.${prompt_id}`
                }
            }
        ).then(response => {
            const votings = response.data as PromptVotingType[]
            let num_votings = {
                [PromptRatingEnum.BAD]: 0,
                [PromptRatingEnum.OK]: 0,
                [PromptRatingEnum.GOOD]: 0
            }

            votings.forEach(voting => {
                num_votings[voting.rating] += 1
            })

            const total_num_votings = votings.length
            const voting_percentages: Record<PromptRatingEnum, number> = {
                [PromptRatingEnum.BAD]: num_votings[PromptRatingEnum.BAD] / total_num_votings,
                [PromptRatingEnum.OK]: num_votings[PromptRatingEnum.OK] / total_num_votings,
                [PromptRatingEnum.GOOD]: num_votings[PromptRatingEnum.GOOD] / total_num_votings,
            }

            return {
                num_votings,
                voting_percentages,
                total_num_votings: votings.length
            }
        })
    }, [dbConnector])

    const getPromptVotingOfUser = useCallback((
        prompt_id: number,
        created_by: string
    ): Promise<PromptVotingType | null> => {
        return dbConnector.get(
            "/prompt_voting",
            {
                params: {
                    prompt_id: `eq.${prompt_id}`,
                    created_by: `eq.${created_by}`
                }
            }
        ).then(response => {
            if (response.data.length === 0) {
                return null
            }
            return response.data[0]
        })
    }, [dbConnector])

    return useMemo(() => {
        return {
            createPromptVoting,
            updatePromptVoting,
            createOrUpdatePromptVoting,
            getPromptVotings,
            getPromptVotingOfUser
        }
    }, [createPromptVoting, updatePromptVoting, createOrUpdatePromptVoting, getPromptVotings, getPromptVotingOfUser])
}
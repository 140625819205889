import {CategoryType} from "./CategoryType";
import {PlaceholderWithId} from "./TextPlaceholder";

export interface PromptType {
    id: number,
    text: string,
    description: string,
    created_by: string,
    created_at: Date,
    last_changed_at: Date,
    last_changed_by: string,
    version: number
}

export interface PromptWithCategoriesType {
    prompt: PromptType,
    categories: CategoryType[]
}

export interface FullPromptType {
    prompt: PromptType,
    categories: CategoryType[],
    placeholders: PlaceholderWithId[]
}

export interface PromptRevision {
    id: number,
    prompt_id: number,
    text: string,
    description: string,
    created_by: string,
    created_at: Date,
    revision_created_by: string,
    revision_created_at: Date,
    version: number
}

export enum PromptRatingEnum {
    BAD = "bad",
    OK = "ok",
    GOOD = "good"
}

export interface PromptVotingType {
    prompt_id: number,
    rating: PromptRatingEnum,
    created_by: string,
    created_at: Date,
    last_changed_at: Date
}

export interface PromptVotingResultType {
    num_votings: Record<PromptRatingEnum, number>,
    voting_percentages: Record<PromptRatingEnum, number>,
    total_num_votings: number
}

export const EmptyPrompt: PromptType = {
    id: -1,
    text: "",
    description: "",
    created_by: "",
    created_at: new Date(),
    last_changed_at: new Date(),
    last_changed_by: "",
    version: 0
}

export const EmptyPromptVotingResultType: PromptVotingResultType = {
    num_votings: {
        [PromptRatingEnum.BAD]: 0,
        [PromptRatingEnum.OK]: 0,
        [PromptRatingEnum.GOOD]: 0
    },
    voting_percentages: {
        [PromptRatingEnum.BAD]: 0,
        [PromptRatingEnum.OK]: 0,
        [PromptRatingEnum.GOOD]: 0
    },
    total_num_votings: 0
}
import "./AnswerForm.scss"
import React, {ChangeEventHandler, FormEvent, useEffect, useState} from 'react';
import {TextPlaceholder, TextPlaceholderSubstitution} from "../../../types/TextPlaceholder";
import {ButtonGroup, Form, FormProps} from "react-bootstrap";
import LoadingButton from "../../LoadingButton/LoadingButton";
import _, {parseInt} from "lodash";
import {LLMType} from "../../../types/LLMTypes";
import {PromptRevision} from "../../../types/Prompt";
import {useAuth} from "react-oidc-context";
import {useDatabase} from "../../../connectors/useDatabase";
import {AnswerType} from "../../../types/AnswerTypes";
import AutoResizingTextArea from "../../AutoResizingTextArea/AutoResizingTextArea";

interface AnswerFormProps extends FormProps {
    prompt_id: number
    onSubmitAnswer: (text: string, llm_id: number, prompt_revision_id: number, substitutions: TextPlaceholderSubstitution, notes: string) => Promise<any>
    initialAnswer?: AnswerType
}

/**
 * This is a bootstrap Form Element, that allows the creation/manipulation of answers.
 * The onSubmitAnswer callback is called when the user submits the changes. It must return a promise that resolves after
 * the successful execution of the user submission.
 *
 * The available LLMs and prompt revisions are automatically fetched from the database.
 *
 * @param prompt_id the id of the prompt for which an answer should be created/updated.
 * @param onSubmitAnswer callback function that is called when the user submits the changes. Must return promise that
 *      resolves after the successful submition of the user changes.
 * @param initialAnswer provides initial values for the form fields.
 * @param props other properties that are directly passed to the top-level bootstrap-Form-element
 * @constructor
 */
function AnswerForm({prompt_id, onSubmitAnswer, initialAnswer, ...props}: AnswerFormProps) {
    const [validated, setValidated] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [availableLLMs, setAvailableLLMs] = useState<LLMType[]>([])
    const [availablePromptRevisions, setAvailablePromptRevisions] = useState<PromptRevision[]>([])
    const [availablePlaceholders, setAvailablePlaceholders] = useState<TextPlaceholder[]>([])

    const [answerText, setAnswerText] = useState<string>(initialAnswer?.text ?? "")
    const [selectedLLM, setSelectedLLM] = useState<number>(initialAnswer?.llm_id ?? 0)
    const [selectedRevisionId, setSelectedRevisionId] = useState<number>(initialAnswer?.prompt_revision_id ?? 0)
    const [selectedRevisionText, setSelectedRevisionText] = useState<string>("")
    const [substitutions, setSubstitutions] = useState<TextPlaceholderSubstitution>({})
    const [notes, setNotes] = useState<string>("")

    const auth = useAuth();
    const db = useDatabase(auth.user?.access_token ?? '')

    // =========================================================================
    // ======================= useEffect-Hooks =================================
    // =========================================================================
    useEffect(() => {
        db.getAllLLMs().then(availableLLMs => {
            setAvailableLLMs(availableLLMs)
            setSelectedLLM(availableLLMs[0]?.id ?? 0)
        })
    }, [db])

    useEffect(() => {
        db.getRevisionsOfPrompt(prompt_id).then(revisions => {
            setAvailablePromptRevisions(revisions)
            setSelectedRevisionId(revisions[0]?.id ?? 0)
            setSelectedRevisionText(revisions[0]?.text ?? "")
        })
    }, [prompt_id, db])

    useEffect(() => {
        const selectedRevision = availablePromptRevisions.find(revision => revision.id === selectedRevisionId)
        setAvailablePlaceholders(TextPlaceholder.findPlaceholders(selectedRevision?.text ?? ''))
        setSelectedRevisionText(selectedRevision?.text ?? "")
    }, [selectedRevisionId, availablePromptRevisions])


    // =========================================================================
    // ======================= handle callbacks ================================
    // =========================================================================
    const handleFormChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> = (event) => {
        switch (event.target.id) {
            case "llm":
                setSelectedLLM(parseInt(event.target.value));
                break;
            case "answer-text":
                setAnswerText(event.target.value);
                break;
            case "revision":
                const selectedRevisionId = parseInt(event.target.value)
                setSelectedRevisionId(selectedRevisionId);
                break;
        }
    }

    const handleSubstitutionChange = (key: string, substitution: string) => {
        let newSubstitutions = _.cloneDeep(substitutions)
        newSubstitutions[key] = substitution
        setSubstitutions(newSubstitutions)
    }

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            setValidated(true);
            return
        }

        // input is validated
        setIsLoading(true)
        onSubmitAnswer(answerText, selectedLLM, selectedRevisionId, substitutions, notes)
            .then(() => {
                setIsLoading(false)
                reset()
            })
            .catch(() => {
                setIsLoading(false)
            })
    }

    const reset = () => {
        setAnswerText("")
        setSubstitutions({})
        setNotes("")
        setValidated(false)
    }

    // =========================================================================
    // ======================= optional elements ===============================
    // =========================================================================
    const usedSubstitutionInput = <>
        <h6>Ersetzungen der Platzhalter:</h6>

        <table>
            <tbody>
            {
                availablePlaceholders.map(placeholder => {
                    return <tr key={placeholder.key}>
                        <td>{placeholder.key}:</td>
                        <td className={"answer-form-revision-text-substitution-input"}>
                            <Form.Control placeholder={`Genutzte Ersetzung für '${placeholder.key}'`}
                                          as={'textarea'}
                                          key={placeholder.key}
                                          id={'substitution' + substitutions[placeholder.key]}
                                          onChange={(event) => handleSubstitutionChange(placeholder.key, event.target.value)}
                                          value={substitutions[placeholder.key] ?? ''}
                                          rows={1}
                                          required={true}
                            />
                        </td>
                    </tr>
                })
            }
            </tbody>
        </table>
    </>

    return (
        <Form {...props}
              noValidate
              validated={validated}
              className={'answer-form-container'}
              onSubmit={handleSubmit}>
            <Form.Group className={'answer-form-llm'}>
                <Form.Select size={"sm"}
                             id={'llm'}
                             className={'answer-form-llm-select'}
                             aria-label={'Befragtes LLM'}
                             value={selectedLLM} onChange={handleFormChange}>
                    {
                        availableLLMs.map(llm => <option value={llm.id} key={llm.id}>{llm.name}</option>)
                    }
                </Form.Select>
            </Form.Group>

            <Form.Group>
                <Form.Control required
                              id={'answer-text'}
                              as={'textarea'}
                              placeholder={'Antwort des LLMs...'}
                              value={answerText}
                              onChange={handleFormChange}
                />
            </Form.Group>

            <h6>Genutzte Prompt-Version:</h6>
            <Form.Select size={'sm'}
                         id={'revision'}
                         value={selectedRevisionId}
                         onChange={handleFormChange}
            >
                {
                    availablePromptRevisions.map((revision, idx) => {
                        return <option value={revision.id} key={revision.id}>
                            Version {revision.version} von {revision.revision_created_by} am {revision.revision_created_at.toLocaleString()}
                            {idx === 0 && " (Aktuellste Version)"}
                        </option>
                    })
                }
            </Form.Select>

            <div className={'answer-form-revision-text'}>
                {selectedRevisionText}
            </div>

            {availablePlaceholders.length > 0 && usedSubstitutionInput}

            <h6>Notizen:</h6>
            <AutoResizingTextArea value={notes} onChangeValue={setNotes} placeholder={"Notizen zur Antwort. Beispielsweise die exakte Version des LLMs oder Temperatur."}/>

            <ButtonGroup className={'answer-form-button'}>
                <LoadingButton variant="primary" type="submit" isLoading={isLoading} size={"sm"}>
                    Antwort hinzufügen
                </LoadingButton>
            </ButtonGroup>
        </Form>
    )
}

export default AnswerForm;
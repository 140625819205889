import {createContext} from "react";
import {TextPlaceholderSubstitution} from "../../types/TextPlaceholder";

interface PlaceholderSubstitutionCtxValueType {
    /**
     * An object that maps the substitutions to each key.
     */
    substitutions: TextPlaceholderSubstitution,
    /**
     * With this function, you can set the substitution for a specific placeholder key.
     * @param key the key of the placeholder for which a substitution should be added
     * @param substitution the actual substitution
     */
    setSubstitution: (key: string, substitution: string) => void
}

export const PlaceholderSubstitutionCtx = createContext<PlaceholderSubstitutionCtxValueType>({
    substitutions: {},
    setSubstitution: () => {},
})
import "./LLMCreate.scss"
import React, {ChangeEventHandler, FormEvent, HTMLAttributes, useState} from 'react';
import {Form} from "react-bootstrap";
import LoadingButton from "../../LoadingButton/LoadingButton";
import {useAuth} from "react-oidc-context";
import {useDatabase} from "../../../connectors/useDatabase";

interface CreateLLMProps extends HTMLAttributes<HTMLDivElement> {

}

function LLMCreate({...props}: CreateLLMProps) {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [name, setName] = useState<string>("")
    const [duplicateError, setDuplicateError] = useState<boolean>(false)

    const auth = useAuth()
    const db = useDatabase(auth.user?.access_token ?? "")

    const handleNameChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setName(event.target.value)
        if (duplicateError) {
            setDuplicateError(false)
        }
    }

    const handleReset = () => {
        setName("")
        setDuplicateError(false)
    }

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
            return
        }

        event.preventDefault();

        setIsLoading(true)
        db.createLLM(name).then(() => {
            setIsLoading(false)
            handleReset()
        }).catch(error => {
            if (error.isConflictError) {
                setIsLoading(false)
                setDuplicateError(true)
            }
        })
    }

    return (
        <div {...props}>
            <Form noValidate
                  className={'llm-create-input'}
                  onSubmit={handleSubmit}
            >
                <Form.Group className={"llm-create-input-fields"}>
                    <Form.Control
                        required={true}
                        placeholder={"Name des neuen LLMs"}
                        aria-label={"Name des neuen LLMs"}
                        onChange={handleNameChange}
                        value={name}
                        isValid={name.length > 0 && !duplicateError}
                        isInvalid={duplicateError}
                    />
                    <Form.Control.Feedback type="invalid">
                        LLM mit diesem Namen existiert bereits.
                    </Form.Control.Feedback>
                </Form.Group>
                <LoadingButton type={'submit'} isLoading={isLoading} disabled={name.length === 0}>
                    Speichern
                </LoadingButton>
            </Form>
        </div>
    )
}

export default LLMCreate;
import "./LongText.scss"
import React, {HTMLAttributes, useContext, useEffect, useLayoutEffect, useRef, useState} from 'react';
import CopyButton from "../CopyButton/CopyButton";
import {TextPlaceholder} from "../../types/TextPlaceholder";
import {TemplatedText} from "../../types/TemplatedText";
import TextPlaceholderView from "../TextPlaceholderView/TextPlaceholderView";
import {PlaceholderSubstitutionCtx} from "../../context/PlaceholderSubstitutionCtx/PlaceholderSubstitutionCtx";
import {FaAngleDown, FaAngleUp} from "react-icons/fa";

interface LongTextProps extends HTMLAttributes<HTMLDivElement> {
    text: string,
    placeholders?: TextPlaceholder[],
    className?: string,
    editable?: boolean,
    maxHeight?: number,
}

function LongText({text, placeholders, editable, maxHeight, ...props}: LongTextProps) {
    const [templatedText, setTemplatedText] = useState<TemplatedText>(new TemplatedText("", []))
    const [textComponents, setTextComponents] = useState<string[]>([])
    const [placeholderComponents, setPlaceholderComponents] = useState<TextPlaceholder[]>([])
    const {substitutions} = useContext(PlaceholderSubstitutionCtx)
    const textContainerRef = useRef<HTMLDivElement>(null);
    const [textContainerContentHeight, setTextContainerContentHeight] = useState<number>(0)

    // whether the content is larger than the maximum with
    const [isOverflowing, setIsOverflowing] = useState<boolean>(false);
    // whether the overflow should be shown (toggleable via the 'show more' button)
    const [showOverflow, setShowOverflow] = useState<boolean>(false)

    const contentEditable = editable ?? true;

    useEffect(() => {
        setTemplatedText(new TemplatedText(text, placeholders ?? []))
    }, [text, placeholders])

    useEffect(() => {
        if (textContainerRef.current !== null) {
            setTextContainerContentHeight(textContainerRef.current.scrollHeight)
        }
    }, [textContainerRef, templatedText])

    // check if content is overflowing and add the "show more" button if necessary
    useLayoutEffect(() => {
        if (textContainerRef.current === null) {
            return
        }
        if (maxHeight === undefined || textContainerContentHeight < maxHeight) {
            setIsOverflowing(false)
        } else {
            setIsOverflowing(true)
        }
    }, [maxHeight, text, placeholders, templatedText, textComponents, textContainerContentHeight])

    useEffect(() => {
        const splitText = templatedText.splitTextAndPlaceholders()
        setTextComponents(splitText.textComponents)
        setPlaceholderComponents(splitText.placeholderComponents)
    }, [templatedText])


    const switchShowOverflow = () => {
        setShowOverflow(!showOverflow)
    }

    return (
        <div {...props}>
            <div className={'long-text-container'}>
                <div className={'long-text'} ref={textContainerRef}
                     style={{maxHeight: showOverflow ? textContainerContentHeight : maxHeight}}>
                    {textComponents.map((text, index) => {
                        if (index < placeholderComponents.length) {
                            return <div className={'long-text'} key={index}>
                                {text}
                                <TextPlaceholderView placeholder={placeholderComponents[index]}
                                                     editable={contentEditable}/>
                            </div>
                        }
                        // we always have one text component more than placeholder components.
                        // The last text may be empty, but it always exists.
                        return <div className={'long-text'} key={index}>{text}</div>
                    })}
                </div>
                <CopyButton text={templatedText.substitute(substitutions, false)}/>
            </div>
            {
                isOverflowing &&
                <button onClick={switchShowOverflow}
                        className={'button-no-color link-text long-text-expander'}
                >
                    {showOverflow ? <span><FaAngleUp aria-label={'Pfeil hoch'}/> Weniger Anzeigen</span> :
                        <span><FaAngleDown aria-label={'Pfeil runter'}/> Mehr Anzeigen</span>
                    }
                </button>
            }
        </div>
    )
}

export default LongText;
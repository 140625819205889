import "./EditPrompt.scss"
import React, {useEffect, useState} from 'react';
import {FormProps} from "react-bootstrap";
import {EmptyPrompt, FullPromptType, PromptType} from "../../../types/Prompt";
import {useAuth} from "react-oidc-context";
import {useDatabase} from "../../../connectors/useDatabase";
import {TextPlaceholder} from "../../../types/TextPlaceholder";
import PromptForm from "../PromptForm/PromptForm";

interface EditPromptProps extends FormProps {
    prompt_id: number,
    onCancel?: () => void,
    onSuccessfulSubmit?: (updatedPrompt: FullPromptType) => void,
}

function EditPrompt({prompt_id, onCancel, onSuccessfulSubmit, ...props}: EditPromptProps) {
    const [oldPrompt, setOldPrompt] = useState<PromptType>(EmptyPrompt)
    const [newPromptCategoryIds, setNewPromptCategoryIds] = useState<number[]>([])

    // Case: prompt contains placeholder notation that was not selected
    //              => this is indirectly used to set these placeholder as unselected per default
    const [selectedPlaceholderKeys, setSelectedPlaceholderKeys] = useState<string[]>()


    const auth = useAuth()
    const db = useDatabase(auth.user?.access_token || '')

    // query the prompt to get all information, including categories and placeholders
    useEffect(() => {
        db.getPrompt(prompt_id).then(({prompt, categories, placeholders}) => {
            setOldPrompt(prompt)
            setNewPromptCategoryIds(categories.map(category => category.id))
            const selectedPlaceholderKeys = placeholders.map(placeholder => placeholder.placeholder.key)
            setSelectedPlaceholderKeys(selectedPlaceholderKeys)
        })
    }, [prompt_id, db])

    const handleSubmit = (prompt: string, description: string, selectedCategoryIds: number[], selectedPlaceholders: TextPlaceholder[]) => {
        console.log("update prompt:", prompt, description, selectedCategoryIds, selectedPlaceholders)
        const username = auth.user?.profile.preferred_username ?? ''
        db.updatePrompt(prompt_id, prompt, description, username, selectedCategoryIds, selectedPlaceholders)
            .then(() => {
                console.log("prompt updated")
                if (onSuccessfulSubmit !== undefined) {
                    db.getPrompt(prompt_id).then(prompt => {
                        onSuccessfulSubmit(prompt)
                    })
                }
            })
    }


    return (
        <PromptForm
            {...props}
            defaultPromptText={oldPrompt.text}
            defaultPromptDescription={oldPrompt.description}
            defaultSelectedCategoryIds={newPromptCategoryIds}
            defaultSelectedPlaceholderKeys={selectedPlaceholderKeys}
            created_by={oldPrompt.created_by}
            created_at={oldPrompt.created_at}
            onSubmitPrompt={handleSubmit}
            onCancel={onCancel}
        />
    )
}

export default EditPrompt;
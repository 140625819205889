import "./PromptViewRating.scss"
import React, {HTMLAttributes, useEffect, useState} from 'react';
import {PromptVotingResultType} from "../../../types/Prompt";
import {FaRegFrown, FaRegMeh, FaRegMehBlank, FaRegSmile} from "react-icons/fa";

interface PromptViewRatingIconProps extends HTMLAttributes<HTMLDivElement> {
    avgVoting: number
}

/**
 * This component returns an appropriate icon according to an average voting.
 * This voting should be in the range [-1, 1].
 * -1 represents a bad voting
 * 0 an ok voting
 * 1 a good voting
 *
 * The range is equally separated into three icons.
 *
 * @param avgVoting the average voting in the range [-1, 1]
 * @constructor
 */
function PromptViewRatingIcon({avgVoting}: PromptViewRatingIconProps) {
    if (Number.isNaN(avgVoting)) {
        return <FaRegMehBlank className={'unknown-rating'}
                              title={'Neutrales Gesicht ohne Mund'}
                              aria-label={'Bewertung unbekannt'}
        />
    }

    if (Math.abs(avgVoting) < 0.34) {
        return <FaRegMeh className={'rating-ok'}
                         title={'Neutrales Gesicht'}
                         aria-label={'Neutral bewertet'}
        />
    }
    if (avgVoting < 0) {
        return <FaRegFrown className={'rating-bad'}
                           title={'Trauriges Gesicht'}
                           aria-label={'Schlecht bewertet'}
        />
    }
    return <FaRegSmile className={'rating-good'}
                       title={'Lächelndes Gesicht'}
                       aria-label={'Gut bewertet'}
    />
}

interface PromptViewRatingProps extends HTMLAttributes<HTMLDivElement> {
    prompt_voting: PromptVotingResultType
}

function PromptViewRating({prompt_voting, ...props}: PromptViewRatingProps) {
    const [avgVoting, setAvgVoting] = useState<number>(0)

    useEffect(() => {
        const num_good = prompt_voting.num_votings.good
        const num_bad = prompt_voting.num_votings.bad
        setAvgVoting((num_good - num_bad) / prompt_voting.total_num_votings)
    }, [prompt_voting])

    return (
        <div {...props} className={'prompt-view-rating ' + (props.className ?? '')}>
            <PromptViewRatingIcon avgVoting={avgVoting}/>
        </div>
    )
}

export default PromptViewRating;
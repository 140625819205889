import axios from "axios";
import {useMemo} from "react";

/**
 * This is the basic controller that just handles the token injection.
 * All other controllers/hooks use this connector
 * @param token
 * @param abortController
 */
export const useDatabaseConnector = (token: string, abortController: AbortController | null = null) => {
  return useMemo( () => axios.create({
    baseURL: process.env.REACT_APP_DB_URL,
    headers: {
      Authorization: `Bearer ${token}`
    },
    signal: abortController?.signal
  }), [token, abortController?.signal])
}
import "./DeleteModal.scss"
import React, {ReactElement} from 'react';
import {Modal} from "react-bootstrap";
import {ModalProps} from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

interface DeleteModalProps extends ModalProps {
    title: string | ReactElement
    onConfirmDelete: () => void
    show: boolean
}

/**
 * This is a modal that can be used to confirm the deletion of an element.
 * It uses a bootstrap Model component. All props of the bootstrap modal are also supported and directly passed to
 * the bootstrap version.
 *
 * The children are drawn as the body of the modal.
 *
 * Use the show property to show/hide this modal.
 *
 * NOTE: some default values of the bootstrap modal are changed:
 *  - backdrop: 'static'
 *
 * @param title text that is shown as title of the modal
 * @param onConfirmDelete this method is called when the deletion is confirmed by the user
 * @param show whether this modal should be shown
 * @param onHide callback function when the modal should be hidden
 * @param props
 * @constructor
 */
function DeleteModal({title, onConfirmDelete, show, onHide, ...props}: DeleteModalProps) {
    return (
        <Modal show={show}
               backdrop={props.backdrop ?? 'static'}
               onHide={onHide}
               {...props}
        >
            <Modal.Header closeButton={true}>
                <Modal.Title>
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.children}
            </Modal.Body>
            <Modal.Footer>
                <Button variant={'secondary'}
                        onClick={onHide}
                >
                    Abbrechen
                </Button>
                <Button variant={'danger'}
                        onClick={onConfirmDelete}
                >
                    Löschen
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteModal;
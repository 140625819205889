import {createContext} from 'react';

export type PaginationRange = {
    offset: number,
    limit: number
}

export interface PaginationCtxValueType {
    /**
     * Number of the current page (starts at 0)
     */
    currentPage: number,
    /**
     * number of entries per page.
     * negative values represent unlimited amount
     */
    entriesPerPage: number,
    range: PaginationRange,
    setTotalNumEntries: (totalNumEntries: number) => void
}

export const PaginationCtx = createContext<PaginationCtxValueType>({
    currentPage: 0,
    entriesPerPage: -1,
    range: {offset: 0, limit: 0},
    setTotalNumEntries: () => {}
})
import {useDatabaseConnector} from "./useDatabaseConnector";
import {PromptRevision} from "../../types/Prompt";
import {useCallback, useMemo} from "react";

export const usePromptRevisionEndpoints = (token: string, abortController: AbortController | null = null) => {
    const dbConnector = useDatabaseConnector(token, abortController);

    const transformReturnedPromptRevision = useCallback((
        raw_prompt: any
    ): PromptRevision => {
        return {
            id: raw_prompt.id,
            prompt_id: raw_prompt.prompt_id,
            text: raw_prompt.text,
            description: raw_prompt.description,
            created_by: raw_prompt.created_by,
            created_at: new Date(raw_prompt.created_at),
            revision_created_by: raw_prompt.revision_created_by,
            revision_created_at: new Date(raw_prompt.revision_created_at),
            version: raw_prompt.version
        }
    }, [])

    const getRevisionsOfPrompt = useCallback((
        prompt_id: number
    ): Promise<PromptRevision[]> => {
        return dbConnector.get(
            "/prompt_revision",
            {
                params: {
                    prompt_id: `eq.${prompt_id}`,
                    order: `version.desc`
                }
            }
        ).then(response => response.data.map(transformReturnedPromptRevision))
    }, [dbConnector, transformReturnedPromptRevision])

    return useMemo(() => {
        return {getRevisionsOfPrompt}
    }, [getRevisionsOfPrompt])
}
import "./CreateRating.scss"
import React, {HTMLAttributes, useEffect, useState} from 'react';
import Button from "react-bootstrap/Button";

interface CreateRatingProps extends HTMLAttributes<HTMLDivElement> {
    currentRating?: number
    onSubmitRating?: (rating: number) => void
    numStars?: number
}

function CreateRating({
                          currentRating = 0,
                          onSubmitRating = () => {},
                          numStars = 5,
                          ...props
                      }: CreateRatingProps) {
    const [temporaryRating, setTemporaryRating] = useState<number>(currentRating)

    useEffect(() => {
        setTemporaryRating(currentRating)
    }, [currentRating])

    const resetTemporaryRating = () => {
        setTemporaryRating(currentRating)
    }

    return (
        <div className={'rating-stars unselectable'}
             {...props}
             onMouseLeave={resetTemporaryRating}
             onBlur={resetTemporaryRating}
        >
            {
                Array.from(Array(numStars).keys()).map(starNum => {
                    const representedRating = starNum + 1
                    const selected = temporaryRating >= representedRating
                    const additionalClassName = selected ? 'rating-star-selected' : 'rating-star-unselected'
                    return <Button
                        className={'button-no-color inline-button ' + additionalClassName}
                        size={'lg'}
                        onMouseOver={() => setTemporaryRating(representedRating)}
                        onFocus={() => setTemporaryRating(representedRating)}
                        onClick={() => onSubmitRating(representedRating)}
                        key={starNum}
                        aria-label={`${representedRating} von ${numStars} Sternen`}
                    >
                        {selected ? <span>★</span> : <span>☆</span>}
                    </Button>
                })
            }
        </div>
    )
}

export default CreateRating;
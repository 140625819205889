import React, {DetailedHTMLProps, HTMLAttributes, useEffect, useState} from 'react';
import LongText from "../../LongText/LongText";

import "./Comment.scss"
import {CommentType} from "../../../types/CommentType";
import Timestamp from "../../Timestamp/Timestamp";

interface CommentProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    comment: CommentType,
    headingTemplate: string,
    timestampTemplate: string,
}

function Comment({
                     comment,
                     headingTemplate,
                     timestampTemplate,
                     ...props
                 }: CommentProps) {
    const [expandedHeading, setExpandedHeading] = useState<string>(headingTemplate)

    useEffect(() => {
        setExpandedHeading(headingTemplate.replace('{{USER}}', comment.created_by))
    }, [headingTemplate, comment.created_by])

    return (
        <div {...props} className={'comment ' + (props.className ?? '')}>
            <div className={'comment-header'}>
                <h6 className={'comment-heading'}>{expandedHeading}</h6>
            </div>
            <LongText text={comment.text}/>
            <div className={'comment-footer'}>
                <Timestamp timestamp={comment.created_at}
                           timestampTextTemplate={timestampTemplate}
                           className={'comment-timestamp footnote-text'}
                />
            </div>
        </div>
    )
}

export default Comment;
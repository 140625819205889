import "./AutoResizingTextArea.scss"
import React, {useCallback, useEffect} from 'react';
import {Form, FormControlProps} from "react-bootstrap";
import {ChangeEventHandler, useRef} from "react";

interface AutoResizingTextAreaProps extends FormControlProps {
    value: string,
    onChangeValue: (newValue: string) => void,
    maxHeight?: number | string,
    required?: boolean
}

/**
 * This component is a textarea form input that automatically resizes the height to fit the text.
 *
 * It is a **controlled input component**, i.e., the value of the textarea **must be** handled by
 * the parent component! (see props value and onChangeValue)
 *
 * You can provide a maximum height. If the height needed to display the whole text would be higher than this height,
 * it is capped at the maximum which results in a scroll bar.
 *
 * It uses the Form.Control component from bootstrap (with as='textarea')
 *
 * @param value the current value of the text area, i.e., the
 * @param onChangeValue callback function that is called whenever the value should change due to user interaction.
 *      If you ignore this callback, the value is fixed.
 * @param maxHeight the maximum height up to which the component is allowed to expand.
 *      You can provide the height in any valid css notation.
 * @param props other properties that are directly passed to the Form.Control component.
 * @constructor
 */
function AutoResizingTextArea({value, onChangeValue, maxHeight, ...props}: AutoResizingTextAreaProps) {
    const inputFieldRef = useRef<HTMLTextAreaElement>(null)

    const resize = useCallback(() => {
        if (inputFieldRef.current !== null) {
            inputFieldRef.current.style.height = 'auto'
            const neededHeight = inputFieldRef.current.scrollHeight + 2 + "px"
            if (maxHeight === null || maxHeight === undefined) {
                inputFieldRef.current.style.height = neededHeight
            } else {
                inputFieldRef.current.style.height = `min(${neededHeight}, ${maxHeight})`
            }
        }
    }, [inputFieldRef, maxHeight])

    useEffect(() => resize(), [resize])

    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        event.preventDefault()
        onChangeValue(event.target.value)
        resize()
        props.onChange?.(event)
    }

    return (
        <Form.Control
            {...props}
            required={props.required}
            as={'textarea'}
            onChange={handleChange}
            onCut={(event) => {
                resize()
                props.onCut?.(event)
            }}
            onPaste={(event) => {
                resize()
                props.onPaste?.(event)
            }}
            onFocus={(event) => {
                resize()
                props.onFocus?.(event)
            }}
            value={value}
            ref={inputFieldRef}
            rows={1}
        />
    )
}

export default AutoResizingTextArea;
import React, {useEffect, useState} from 'react';
import CommentList from "../../CommentList/CommentList";
import {CommentType} from "../../../types/CommentType";
import {useAuth} from "react-oidc-context";
import {useDatabase} from "../../../connectors/useDatabase";
import {FaRegComments} from "react-icons/fa";

interface PromptCommentsProps {
    prompt_id: number
}

function PromptComments({prompt_id}: PromptCommentsProps) {
    const [comments, setComments] = useState<CommentType[]>([])
    const [fetchComments, setFetchComments] = useState<boolean>(true)
    const auth = useAuth()
    const db = useDatabase(auth.user?.access_token ?? '')

    useEffect(() => {
        if (fetchComments) {
            db.getComments(prompt_id).then(setComments)
            setFetchComments(false)
        }
    }, [prompt_id, fetchComments, db])

    const handleCreateComment = (comment: string, username: string) => {
      db.createComment(comment, username, prompt_id).then(() => setFetchComments(true))
    }

    return (
        <div>
            <h4><FaRegComments/>&emsp; Kommentare</h4>
            <CommentList prompt_id={prompt_id}
                         comments={comments}
                         enableCommentCreation={true}
                         onCreateComment={handleCreateComment}
            />
        </div>
    )
}

export default PromptComments;
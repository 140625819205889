import {useDatabaseConnector} from "./useDatabaseConnector";
import {PromptCommentType} from "../../types/PromptCommentType";
import {useCallback, useMemo} from "react";

function transformRawComment(raw_answer: PromptCommentType): PromptCommentType {
    return {
        id: raw_answer.id,
        text: raw_answer.text,
        created_at: new Date(raw_answer.created_at),
        last_changed: new Date(raw_answer.last_changed),
        created_by: raw_answer.created_by,
        prompt_id: raw_answer.prompt_id
    }
}

export const useCommentEndpoints = (token: string, abortController: AbortController | null = null) => {
    const dbConnector = useDatabaseConnector(token, abortController)

    /**
     * Returns a promise with all comments for a given prompt.
     * @param prompt_id
     */
    const getComments = useCallback((
        prompt_id: number
    ): Promise<PromptCommentType[]> => {
        return dbConnector.get(
            "/comment",
            {
                params: {
                    prompt_id: `eq.${prompt_id}`
                }
            }
        ).then(response => response.data.map(transformRawComment))
    }, [dbConnector])

    const createComment = useCallback((
        text: string, created_by: string, prompt_id: number
    ) => {
        const currentTime = new Date();
        return dbConnector.post(
            "/comment",
            {
                text,
                created_at: currentTime,
                last_changed: currentTime,
                created_by,
                prompt_id
            }
        )
    }, [dbConnector])

    return useMemo(() => {
        return {getComments, createComment}
    }, [getComments, createComment])
}
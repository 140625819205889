import "./CreateAnswer.scss"
import React from 'react';
import {useAuth} from "react-oidc-context";

import {useDatabase} from "../../../connectors/useDatabase";
import AnswerForm from "../../answer/AnswerForm/AnswerForm";
import {TextPlaceholderSubstitution} from "../../../types/TextPlaceholder";

interface CreateAnswerProps {
    prompt_id: number,
    onAnswerCreated?: () => void,
}

/**
 * This component allows the creation of an LLM answer. It handles the user input and creates the new answer in the
 * database.
 * To hook into the answer creation process, provide the onAnswerCreated callback. It is called after an answer was
 * successfully created.
 *
 * @param prompt_id the id of the prompt for which an answer should be created
 * @param onAnswerCreated callback function that is called AFTER the successful creation of a new answer.
 *      You can use this callback to re-fetch the new answer data after the creation of a new answer.
 * @constructor
 */
function CreateAnswer({prompt_id, onAnswerCreated}: CreateAnswerProps) {
    const auth = useAuth();
    const dbConnector = useDatabase(auth.user?.access_token ?? '')

    const handleSubmit = (text: string, llm_id: number, prompt_revision_id: number, substitutions: TextPlaceholderSubstitution, notes: string) => {
        return dbConnector.createAnswer(text, llm_id, prompt_revision_id, substitutions, notes)
            .then(() => { // call callback function that notifies the parent that a new answer was published
                if (onAnswerCreated !== undefined) {
                    onAnswerCreated()
                }
            })
    }

    return (
        <AnswerForm prompt_id={prompt_id} onSubmitAnswer={handleSubmit}/>
    )
}

export default CreateAnswer;
import "./AnswerList.scss"

import React, {DetailedHTMLProps, HTMLAttributes, useEffect, useState} from 'react';
import {useAuth} from "react-oidc-context";
import {useDatabase} from "../../connectors/useDatabase";
import {AnswerWithIdType} from "../../types/AnswerTypes";
import CreateAnswer from "./CreateAnswer/CreateAnswer";
import AnswerView from "../answer/AnswerView/AnswerView";

interface AnswerListProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    prompt_id: number,
    expandable?: boolean,
    defaultExpanded?: boolean,
}

function AnswerList({prompt_id, expandable, defaultExpanded, ...props}: AnswerListProps) {
    const [answers, setAnswers] = useState<AnswerWithIdType[]>([])
    const [fetchAnswers, setFetchAnswers] = useState<boolean>(true)
    const auth = useAuth();
    const db = useDatabase(auth.user?.access_token ?? '')

    useEffect(() => {
        if (fetchAnswers) {
            db.getAnswers(prompt_id).then(setAnswers)
            setFetchAnswers(false)
        }
    }, [db, prompt_id, fetchAnswers])

    const handleSubmitRating = (answer: AnswerWithIdType, rating: number) => {
        if (auth.user !== undefined && auth.user !== null) {
            db.addOrUpdateReviewToAnswer(answer.id,
                rating,
                auth.user.profile.preferred_username ?? '')
                .then(() => setFetchAnswers(true))
        }
    }

    return (
        <div {...props}>
            <div className={'answer-list'}>
                {
                    answers.map((answer, idx) => {
                        if (idx < answers.length - 1) {
                            return <div key={answer.id}>
                                <AnswerView answer={answer} onSubmitRating={handleSubmitRating}/>
                                <div className={'text-separator'}/>
                            </div>
                        }
                        return <AnswerView key={answer.id} answer={answer} onSubmitRating={handleSubmitRating}/>
                    })
                }
            </div>
            <h4>Neue Antwort Hinzufügen</h4>
            <CreateAnswer prompt_id={prompt_id} onAnswerCreated={() => setFetchAnswers(true)}/>
        </div>
    )
}

export default AnswerList;
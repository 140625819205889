import React, {FormEventHandler, HTMLAttributes, useEffect, useRef, useState} from 'react';

import "./CreateComment.scss"
import Button from "react-bootstrap/Button";
import {useAuth} from "react-oidc-context";

export type CreateCommentCallback = (comment: string, username: string) => void

export interface CreateCommentProps extends HTMLAttributes<HTMLDivElement> {
    onCreateComment?: CreateCommentCallback
}

/**
 * This is a simple component to create new comments.
 * It uses a editable div as input.
 * The onCreateComment callback can be passed to handle the behaviour when save was clicked.
 *
 * @param onCreateComment a callback function that should handle the actual creation of the comment.
 * @param props other props that are directly passed to the top-level div. You can pass e.g. a custom className.
 * @constructor
 */
function CreateComment({onCreateComment, ...props}: CreateCommentProps) {
    const [comment, setComment] = useState<string>("")
    const [editing, setEditing] = useState<boolean>(false)
    const [divClassName, setDivClassName] = useState<string>("")
    const editableDiv = useRef<HTMLDivElement>(null)
    const auth = useAuth()

    useEffect(() => {
        if (editing || comment.length > 0) {
            setDivClassName("create-comment-input")
        } else {
            // we add a class name if the input is not being modified. Hence, we can use the ::before selector
            // to embed the help/label text inside the div in exactly this case
            setDivClassName("create-comment-input create-comment-input-unmodified")
        }
    }, [editing, comment])

    /**
     * This function reads the input inside the editable div.
     * @param event
     */
    const handleInput: FormEventHandler<HTMLDivElement> = (event) => {
        event.preventDefault()
        // @ts-ignore
        setComment(event.target.innerText)
    }

    const handleSave = () => {
        const username = auth.user?.profile.preferred_username ?? ''
        if (onCreateComment !== undefined) {
            onCreateComment(comment, username)
            setComment("")
            if (editableDiv.current !== null) {
                editableDiv.current.innerText = ""
            }
        }
    }



    return (
        <div className={'create-comment'} {...props}>
            <div className={divClassName}
                 onFocus={() => setEditing(true)} // remove placeholder on focus
                 onBlur={() => setEditing(false)} // add placeholder again when focus is lost
                 onInput={handleInput}
                 ref={editableDiv}
                 contentEditable={true}
            />
            {(editing || comment.length > 0) && <Button disabled={comment.length === 0}
                                                        size={'sm'}
                                                        onClick={handleSave}
            >
                Speichern
            </Button>}
        </div>
    )
}

export default CreateComment;
import React, {DetailedHTMLProps, HTMLAttributes, MouseEventHandler, useState} from 'react';
import {FaAngleDown, FaAngleUp} from "react-icons/fa";
import {CommentType} from "../../types/CommentType";
import Comment from "./Comment/Comment";

import "./CommentList.scss"
import CreateComment, {CreateCommentCallback} from "./CreateComment/CreateComment";

interface CommentListProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    prompt_id: number,
    comments: CommentType[]
    headingTemplate?: string,
    timestampTemplate?: string,
    expandable?: boolean,
    defaultExpanded?: boolean,
    noCommentsText?: string,
    enableCommentCreation?: boolean,
    onCreateComment?: CreateCommentCallback
}

/**
 * This component draws a list of comments (or anything similar).
 * The header and footer for each individual comment can be customized (see headingTemplate and timestampTemplate).
 *
 * @param prompt_id: the id of the prompt for which the comments are shown
 * @param comments: the actual comments. You can transform any data in the expected comment format and display it.
 * @param headingTemplate this template is used to customize the header of each comment, i.e., the bold part before the
 *          actual content. In this template, you can use '{{USER}}' to refer to the username for the current comment.
 *          The user will be replaced in the final view.
 * @param timestampTemplate this template is used to customize the footer of each comment, i.e., the gray part
 *          underneath. This is usually used to display the creation time of this comment.
 *          Hence, you can use '{{TIMESTAMP}}' to refer to the creation time of the current comment.
 * @param expandable whether this comment list is expandable, i.e., a link should be drawn on top of the list with
 *          which the comments can be expanded or collapsed.
 * @param defaultExpanded whether the comments should be expanded (true) or collapsed (false) on the creation of this
 *          component.
 *          NOTE: expandable == false and defaultExpanded == false results in a completely empty comment list.
 * @param noCommentsText the text that should be displayed if no comments are passed to this list.
 * @param enableCommentCreation whether a small input field should be drawn at the end of the comments that allows the
 *          creation of new comments.
 * @param onCreateComment the callback function, that is called with the comment text after the user pressed 'save'.
 *          Here you have to handle the actual creation.
 * @param props other props, that are directly passed to the top-level div. You can e.g. pass a custom className.
 * @constructor
 */
function CommentList({
                         prompt_id,
                         comments,
                         headingTemplate,
                         timestampTemplate,
                         expandable,
                         defaultExpanded,
                         noCommentsText,
                         enableCommentCreation,
                         onCreateComment,
                         ...props
                     }: CommentListProps) {
    const [showComments, setShowComments] = useState<boolean>(defaultExpanded ?? true)

    const defaultHeadingTemplate = "{{USER}} kommentiert:"
    const defaultTimestampTemplate = "am {{TIMESTAMP}}"
    const defaultNoCommentsText = "Keine Kommentare vorhanden."

    const handleExpandClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
        e.preventDefault()
        setShowComments(!showComments)
    }

    // define the element to expand/collapse the comments
    let expandText;
    if (!showComments) {
        expandText = <div onClick={() => setShowComments(true)}>
            Antworten der LLMs ({comments.length}) anzeigen <FaAngleDown aria-label={'Pfeil runter'}/>
        </div>
    } else {
        expandText = <div onClick={() => setShowComments(true)}>
            Antworten der LLMs ({comments.length}) ausblenden <FaAngleUp aria-label={'Pfeil hoch'}/>
        </div>
    }

    let commentsComponent;
    if (comments.length > 0) {
        commentsComponent = comments.map((comment, idx) => {
            const mappedComment = <Comment className={'comment-list-comment'}
                                           headingTemplate={headingTemplate ?? defaultHeadingTemplate}
                                           comment={comment}
                                           timestampTemplate={timestampTemplate ?? defaultTimestampTemplate}
                                           key={comment.id}/>

            if (idx < comments.length - 1) {
                // not the last component => draw the separator
                return <div key={comment.id}>
                    {mappedComment}
                    <div className={'text-separator'}/>
                </div>
            } else {
                // the last component => do not draw the separator
                return mappedComment
            }
        })
    } else {
        commentsComponent = <div className={'comment-list-no-comments'}>
            {noCommentsText ?? defaultNoCommentsText}
        </div>
    }

    return (
        <div {...props} className={'comment-list ' + (props.className ?? '')}>
            {expandable &&
                <a onClick={handleExpandClick} className={'comment-list-expand-text'}>
                    {expandText}
                </a>
            }
            {(showComments || !expandable) && commentsComponent}
            {enableCommentCreation && <CreateComment onCreateComment={onCreateComment} className={'create-comment'}/>}
        </div>
    )
}

export default CommentList;
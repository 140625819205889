import {useDatabaseConnector} from "./useDatabaseConnector";
import {AnswerWithIdType} from "../../types/AnswerTypes";
import {TextPlaceholder, TextPlaceholderSubstitution} from "../../types/TextPlaceholder";
import {TemplatedText} from "../../types/TemplatedText";
import {useCallback, useMemo} from "react";

interface UsedSubstitutionType {
    id: number,
    placeholder_key: string,
    substitution: string,
    answer_id: number
}

function transformRawAnswer(raw_answer: AnswerWithIdType): AnswerWithIdType {
    return {
        id: raw_answer.id,
        text: raw_answer.text,
        notes: raw_answer.notes,
        created_at: new Date(raw_answer.created_at),
        last_changed: new Date(raw_answer.last_changed),
        llm_id: raw_answer.llm_id,
        llm_name: raw_answer.llm_name,
        prompt_id: raw_answer.prompt_id,
        prompt_revision_id: raw_answer.prompt_revision_id,
        prompt_version: raw_answer.prompt_version,
        avg_rating: raw_answer.avg_rating,
        num_ratings: raw_answer.num_ratings
    }
}

export const useAnswerEndpoints = (token: string, abortController: AbortController | null = null) => {
    const dbConnector = useDatabaseConnector(token, abortController)

    /**
     * Returns a promise with all answers for a given prompt.
     * @param prompt_id
     */
    const getAnswers = useCallback((
        prompt_id: number
    ): Promise<AnswerWithIdType[]> => {
        return dbConnector.get(
            "/answer_full",
            {
                params: {
                    prompt_id: `eq.${prompt_id}`,
                    order: `created_at.desc`
                }
            }
        ).then(response => response.data.map(transformRawAnswer))
    }, [dbConnector])

    const createAnswer = useCallback((
        text: string,
        llm_id: number,
        prompt_revision_id: number,
        substitutions: TextPlaceholderSubstitution,
        notes: string = ""
    ) => {
        const created_at = new Date();
        const last_changed = created_at;

        return dbConnector.post(
            "/answer",
            {
                text,
                created_at,
                last_changed,
                llm_id,
                prompt_revision_id,
                notes
            },
            {
                headers: {
                    Prefer: "return=representation"
                }
            }
        ).then(response => { // save the substitutions
            const answer_id = response.data[0].id;

            const substitution_promises: Promise<any>[] = []
            for (let placeholder_key in substitutions) {
                substitution_promises.push(
                    dbConnector.post(
                        "/used_substitution",
                        {
                            answer_id,
                            placeholder_key,
                            substitution: substitutions[placeholder_key]
                        }
                    )
                )
            }
            return Promise.all(substitution_promises)
        })
    }, [dbConnector])

    const addOrUpdateReviewToAnswer = useCallback((
        answer_id: number, rating: number, created_by: string
    ) => {
        const created_at = new Date();
        const last_changed = created_at;

        // first check whether this user has already provided a rating for this answer
        return dbConnector.get(
            "/review",
            {
                params: {
                    answer_id: `eq.${answer_id}`,
                    created_by: `eq.${created_by}`
                }
            }
        ).then(response => {
            if (response.data.length === 0) {
                // create new review
                return dbConnector.post(
                    "/review",
                    {
                        answer_id,
                        rating,
                        created_by,
                        created_at,
                        last_changed
                    }
                )
            }
            if (response.data.length === 1) {
                // update the review
                const cur_review = response.data[0]
                return dbConnector.patch(
                    "/review",
                    {
                        rating,
                        last_changed
                    },
                    {
                        params: {
                            id: `eq.${cur_review.id}`
                        }
                    }
                )
            }
            throw new Error('Unreachable, as (answer_id, created_by) is unique.')
        })
    }, [dbConnector])

    const getReview = useCallback((
        answer_id: number, created_by: string
    ): Promise<number> => {
        return dbConnector.get(
            "/review",
            {
                params: {
                    answer_id: `eq.${answer_id}`,
                    created_by: `eq.${created_by}`
                }
            }
        ).then(response => {
            if (response.data.length === 0) {
                return 0
            }
            return response.data[0].rating as number
        })
    }, [dbConnector])

    const getReviews = useCallback((
        answer_ids: number[], created_by: string
    ): Promise<number[]> => {
        let promises = answer_ids.map(answer_id => getReview(answer_id, created_by))
        return Promise.all(promises)
    }, [getReview])

    const getUsedSubstitutions = useCallback((
        answer_id: number
    ): Promise<TextPlaceholderSubstitution> => {
        return dbConnector.get(
            "/used_substitution",
            {
                params: {
                    answer_id: `eq.${answer_id}`
                }
            }
        )
            .then(response => response.data as UsedSubstitutionType[])
            .then(usedSubstitutions => {
                let substitutions: TextPlaceholderSubstitution = {}
                usedSubstitutions.forEach(substitutionTableEntry => {
                    substitutions[substitutionTableEntry.placeholder_key] = substitutionTableEntry.substitution
                })
                return substitutions
            })
    }, [dbConnector])

    const getUsedPrompt = useCallback((
        answer_id: number
    ): Promise<TemplatedText> => {
        return dbConnector.get(
            "/answer_full",
            {
                params: {
                    id: `eq.${answer_id}`,
                    select: 'prompt_text'
                }
            }
        ).then(response => response.data[0])
            .then(answer => {
                return new TemplatedText(
                    answer.prompt_text,
                    TextPlaceholder.findPlaceholders(answer.prompt_text)
                );
            })
    }, [dbConnector])

    const getUsedPromptAndSubstitution = useCallback((
        answer_id: number
    ): Promise<{ prompt: TemplatedText, substitution: TextPlaceholderSubstitution }> => {
        return getUsedPrompt(answer_id)
            .then(prompt => {
                return getUsedSubstitutions(answer_id)
                    .then(substitution => {
                        return {
                            prompt,
                            substitution
                        }
                    })
            })
    }, [getUsedPrompt, getUsedSubstitutions])

    return useMemo(() => {
        return {
            getAnswers,
            createAnswer,
            addOrUpdateReviewToAnswer,
            getReview,
            getReviews,
            getUsedSubstitutions,
            getUsedPrompt,
            getUsedPromptAndSubstitution
        }
    }, [getAnswers, createAnswer, addOrUpdateReviewToAnswer, getReview, getReviews, getUsedSubstitutions, getUsedPrompt, getUsedPromptAndSubstitution])
}
import React, {ChangeEventHandler, FormEventHandler, MouseEventHandler, useEffect, useState} from 'react';
import CategorySelect, {SetSelectedCategories} from "../../Category/CategorySelect/CategorySelect";
import {useAuth} from "react-oidc-context";
import {useDatabase} from "../../../connectors/useDatabase";
import {CategoryType} from "../../../types/CategoryType";
import {useSearchParams} from "react-router-dom";
import {FaAngleRight, FaFilter, FaSearch} from "react-icons/fa";
import {Form, InputGroup} from "react-bootstrap";
import Button from "react-bootstrap/Button";

import "./PromptListFilter.scss"

type PromptListFilterParams = {
    includedCategoryIds: number[],
    excludedCategoryIds: number[]
}

/**
 * This component allows the filtering of a prompt list.
 * Currently only filter by category (white- and blacklist) is allowed.
 *
 * TODO: externalize all the parameters in a context
 *
 * @param includedCategoryIds
 * @param excludedCategoryIds
 * @constructor
 */
function PromptListFilter({
                              includedCategoryIds,
                              excludedCategoryIds
                          }: PromptListFilterParams) {
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [availableCategories, setAvailableCategories] = useState<CategoryType[]>([])
    const [searchPhrase, setSearchPhrase] = useState<string>("")
    const auth = useAuth()
    const db = useDatabase(auth.user?.access_token || '')
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        db.getAllCategories().then(setAvailableCategories)
    }, [db])

    const setIncludedCategoryIds: SetSelectedCategories = (newIncludedCategoryIds) => {
        searchParams.set("include", JSON.stringify(newIncludedCategoryIds ?? []))
        setSearchParams(searchParams)
    }

    const setExcludedCategoryIds: SetSelectedCategories = (newExcludedCategoryIds) => {
        searchParams.set("exclude", JSON.stringify(newExcludedCategoryIds ?? []))
        setSearchParams(searchParams)
    }

    const handleToggleFilter: MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()
        setShowFilter(!showFilter)
    }

    const handleSearchBarUpdate: ChangeEventHandler<HTMLInputElement> = (event) => {
        event.preventDefault()
        const newSearchPhrase = event.target.value
        setSearchPhrase(newSearchPhrase)

        // if the search bar becomes empty, we want to instantly refresh the prompts
        if (!newSearchPhrase) {
            searchParams.set("search", newSearchPhrase)
            setSearchParams(searchParams)
        }
    }

    const handleSearchSubmit: FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault()
        event.stopPropagation()
        searchParams.set("search", searchPhrase)
        setSearchParams(searchParams)
    }

    // =====================================================================================
    // =====================================================================================
    // =========================== Components ==============================================
    // =====================================================================================

    // This is the component that allows the selection of category filters. It is only shown if showFilter == true
    const categoryFilterComponent = <div className={'prompt-list-filter-categories'}>
        <h6 className={'categories-filter-label'}>Kategorie:</h6>
        <div>
            <div className={'categories-filter-select'}>
                <span className={'categories-filter-select-label'}>enthält:</span>
                <CategorySelect availableCategories={availableCategories}
                                selectedCategoryIds={includedCategoryIds}
                                setSelectedCategoryIds={setIncludedCategoryIds}/>
            </div>
            <div className={'categories-filter-select'}>
                <span className={'categories-filter-select-label'}>enthält nicht:</span>
                <CategorySelect availableCategories={availableCategories}
                                selectedCategoryIds={excludedCategoryIds}
                                setSelectedCategoryIds={setExcludedCategoryIds}/>
            </div>
        </div>
    </div>

    return (
        <div className={'prompt-list-filter'}>
            <div className={'prompt-list-filter-header'}>
                <Form onSubmit={handleSearchSubmit} className={'prompt-list-search-bar'}>
                    <InputGroup>
                        <InputGroup.Text><FaSearch aria-label={'Suche'}/></InputGroup.Text>
                        <Form.Control id={'SearchBar'}
                                      value={searchPhrase}
                                      onChange={handleSearchBarUpdate}/>
                        <Button type={'submit'}
                                className={'prompt-list-search-bar-submit'}
                                aria-label={'Suche starten'}
                        >
                            <FaAngleRight/>
                        </Button>
                    </InputGroup>
                </Form>
                <button onClick={handleToggleFilter}
                        className={'clickable button-no-color'}
                        aria-label={`Filter ${showFilter ? 'ausblenden' : 'einblenden'}`}
                >
                    <FaFilter aria-label={'filter'}/>
                </button>
            </div>
            {showFilter && <div className={'prompt-list-filter-body'}>
                {categoryFilterComponent}
            </div>}
        </div>
    )
}

export default PromptListFilter;
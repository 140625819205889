import "./CreateCategoryPage.scss"

import React, {HTMLAttributes, useEffect, useState} from 'react';
import CategoryCreate from "../../../components/Category/CategoryCreate/CategoryCreate";
import CategoryList from "../../../components/Category/CategoryList/CategoryList";
import {CategoryType} from "../../../types/CategoryType";
import {useAuth} from "react-oidc-context";
import {useDatabase} from "../../../connectors/useDatabase";

interface CreateCategoryPageProps extends HTMLAttributes<HTMLDivElement> {
}

function CreateCategoryPage({...props}: CreateCategoryPageProps) {
    const [categories, setCategories] = useState<CategoryType[]>([])
    const auth = useAuth();
    const db = useDatabase(auth.user?.access_token ?? '')

    useEffect(() => {
        db.getAllCategories().then(setCategories)
    }, [db])

    return (
        <div {...props} className={'CreateCategoryPage'}>
            <h5 className={'CreateCategoryPage-heading default-heading'}>Kategorie Hinzufügen:</h5>
            <CategoryCreate/>
            <h5 className={'CreateCategoryPage-heading default-heading'}>Bereits erstellte Kategorien:</h5>
            <CategoryList categories={categories}/>
        </div>
    )
}

export default CreateCategoryPage;
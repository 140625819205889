import React, {HTMLAttributes} from 'react';
import Button from "react-bootstrap/Button";
import {FaEdit, FaSearch, FaTrash} from "react-icons/fa";

interface ToolbarProps<T> extends HTMLAttributes<HTMLDivElement> {
    referencedObject: T,
    showEdit?: boolean,
    onEdit?: (referencedObject: T) => void,
    showDelete?: boolean,
    onDelete?: (referencedObject: T) => void,
    showSearch?: boolean,
    onSearch?: (referencedObject: T) => void
}

function Toolbar<T>({
                        referencedObject,
                        onEdit = () => {},
                        showEdit = true,
                        onDelete = () => {},
                        showDelete = true,
                        onSearch = () => {},
                        showSearch = true,
                        ...props
                    }: ToolbarProps<T>) {
    return (
        <div {...props}
             className={'toolbar ' + props.className ?? ''}>
            {showEdit &&
                <Button className={'button-no-color toolbar-tool'}
                        onClick={() => onEdit(referencedObject)}
                >
                    <FaEdit aria-label={'Bearbeiten'} role={'button'}/>
                </Button>
            }
            {showDelete &&
                <Button className={'button-no-color toolbar-tool'}
                        onClick={() => onDelete(referencedObject)}
                >
                    <FaTrash aria-label={'Löschen'} role={'button'}/>
                </Button>
            }
            {showSearch &&
                <Button className={'button-no-color toolbar-tool'}
                        onClick={() => onSearch(referencedObject)}
                >
                    <FaSearch aria-label={'Suchen'} role={'button'}/>
                </Button>
            }
        </div>
    )
}

export default Toolbar;
export type Color = {
    red: number,
    green: number,
    blue: number
}

/**
 * This function normalizes an 8-bit RGB color to a range of [0,1]
 *
 * @param rgbColor: the color that should be normalized
 */
function normalizeRGBColor(rgbColor: Color) : Color {
    return {
        red: rgbColor.red / 255,
        green: rgbColor.green / 255,
        blue: rgbColor.blue / 255,
    }
}

/**
 * This function calculates the relative luminance of a (normalized) color according to the WCAG 2.1.
 * See https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html#key-terms => "relative luminance" for the formula.
 *
 * The color must be normalized, i.e., each value in the range [0, 1]
 *
 * @param normalizedColor the color where each component is normalized to [0,1]
 */
function calculateRelativeLuminance(normalizedColor: Color) : number {
    const R = normalizedColor.red <= 0.04045? normalizedColor.red/12.92 : (normalizedColor.red + 0.055) ** 2.4
    const G = normalizedColor.green <= 0.04045? normalizedColor.green/12.92 : (normalizedColor.green + 0.055) ** 2.4
    const B = normalizedColor.blue <= 0.04045? normalizedColor.blue/12.92 : (normalizedColor.blue + 0.055) ** 2.4

    return 0.2126 * R + 0.7152 * G + 0.0722 * B
}

export const parseRGBColorCode = (rgb_code: string) => {
    return {
        red: parseInt(rgb_code.substring(1,3), 16),
        green: parseInt(rgb_code.substring(3,5), 16),
        blue: parseInt(rgb_code.substring(5,7), 16),
    }
}

/**
 * Calculates the contrast between two colors according to the WCAG 2.1 (https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html)
 *
 * @param color1 the first color as Color object. Use {@see parseRGBColorCode} to transform an RGB-String to color object.
 * @param color2 the second color as Color object. Use {@see parseRGBColorCode} to transform an RGB-String to color object.
 */
export const calculateContrast = (color1: Color, color2: Color) => {
    const normalizedC1 = normalizeRGBColor(color1)
    const normalizedC2 = normalizeRGBColor(color2)

    const luminanceC1 = calculateRelativeLuminance(normalizedC1)
    const luminanceC2 = calculateRelativeLuminance(normalizedC2)

    const L1 = Math.max(luminanceC1, luminanceC2)
    const L2 = Math.min(luminanceC1, luminanceC2)

    return (L1 + 0.05) / (L2 + 0.05)
}

export const colorToRGBString = (color: Color) : string => {
    const r = Number(color.red).toString(16)
    const g = Number(color.green).toString(16)
    const b = Number(color.blue).toString(16)

    return '#' + r + g + b
}
import {useDatabaseConnector} from "./useDatabaseConnector";
import {PlaceholderWithId, TextPlaceholder} from "../../types/TextPlaceholder";
import {useCallback, useMemo} from "react";


export const usePlaceholderEndpoints = (token: string, abortController: AbortController | null = null) => {
    const dbConnector = useDatabaseConnector(token, abortController)

    const createPlaceholder = useCallback((
        key: string, description: string
    ): Promise<PlaceholderWithId> => {
        return dbConnector.post(
            "/placeholder",
            {
                key,
                description
            },
            {
                headers: {
                    Prefer: "return=representation"
                }
            }
        ).then(response => response.data[0])
    }, [dbConnector])

    /**
     * This endpoints connects a given prompt and placeholder, i.e., it registers the relation in the prompt_to_placeholder table.
     * @param prompt_id the id of the prompt
     * @param placeholder_id the id of the placeholder
     */
    const connectPlaceholder = useCallback((
        prompt_id: number,
        placeholder_id: number
    ) => {
        return dbConnector.post(
            "/prompt_to_placeholder",
            {
                prompt_id,
                placeholder_id
            }
        )
    }, [dbConnector])

    const createAndConnectMultiplePlaceholders = useCallback((
        prompt_id: number,
        placeholders: TextPlaceholder[]
    ) => {
        let createPlaceholderPromises = placeholders.map(placeholder =>
            createPlaceholder(placeholder.key, placeholder.description)
                .then(placeholder => { // register this placeholder as placeholder of the created prompt
                    return connectPlaceholder(prompt_id, placeholder.id)
                })
        )
        return Promise.all(createPlaceholderPromises)
    }, [createPlaceholder, connectPlaceholder])

    const updatePlaceholder = useCallback((
        placeholder_id: number,
        new_key: string,
        new_description: string
    ) => {
        return dbConnector.patch(
            "/placeholder",
            {
                key: new_key,
                description: new_description
            },
            {
                params: {
                    id: `eq.${placeholder_id}`
                }
            }
        )
    }, [dbConnector])

    /**
     * This function deletes the connections between given prompt and placeholder.
     * You can specify whether the placeholder should be automatically deleted, if no prompts with this placeholder
     * exist anymore after the disconnection (@see deleteOrphans)
     *
     * @param prompt_id the id of the prompt from which the placeholder should be disconnected
     * @param placeholder_id the id of the placeholder
     * @param deleteOrphans if true, this function checks if the specified placeholder is a placeholder of other prompts.
     *      If no other prompt has this placeholder, it is automatically deleted.
     *      If deleteOrphans == false, the specified placeholder remains even if no prompt has this placeholder.
     */
    const disconnectPlaceholder = useCallback((
        prompt_id: number,
        placeholder_id: number,
        deleteOrphans: boolean = false
    ): Promise<null> => {
        return dbConnector.delete(
            "/prompt_to_placeholder",
            {
                params: {
                    prompt_id: `eq.${prompt_id}`,
                    placeholder_id: `eq.${placeholder_id}`
                }
            }
        ).then(() => {
            if (!deleteOrphans) {
                return null
            }

            // check if this placeholder is part of other prompts
            return dbConnector.get(
                "/prompt_to_placeholder",
                {
                    params: {
                        placeholder_id: `eq.${placeholder_id}`
                    }
                }
            ).then(response => {
                if (response.data.length > 0) {
                    return null // other prompts have this placeholder
                }

                // No prompt has this placeholder => delete it
                return dbConnector.delete(
                    "/placeholder",
                    {
                        params: {
                            id: `eq.${placeholder_id}`
                        }
                    }
                )
            })
        })
    }, [dbConnector])

    const getPlaceholder = useCallback((
        placeholder_id: number
    ): Promise<PlaceholderWithId> => {
        return dbConnector.get(
            "/placeholder",
            {
                params: {
                    id: `eq.${placeholder_id}`
                }
            }
        ).then(response => {
            if (response.data.length === 0) {
                throw new Error(`No placeholder with id '${placeholder_id}' found.`)
            }
            const placeholderData = response.data[0]
            const placeholder = new TextPlaceholder(placeholderData.key, placeholderData.description)
            return {id: placeholderData.id, placeholder}
        })
    }, [dbConnector])

    const getMultiplePlaceholders = useCallback((
        placeholder_ids: number[]
    ): Promise<PlaceholderWithId[]> => {
        const promises = placeholder_ids.map(getPlaceholder)
        return Promise.all(promises)
    }, [getPlaceholder])

    return useMemo(() => {
        return {
            createPlaceholder,
            createAndConnectMultiplePlaceholders,
            updatePlaceholder,
            connectPlaceholder,
            disconnectPlaceholder,
            getPlaceholder,
            getMultiplePlaceholders
        }
    }, [createPlaceholder, createAndConnectMultiplePlaceholders, updatePlaceholder, connectPlaceholder, disconnectPlaceholder, getPlaceholder, getMultiplePlaceholders])
}
import {useDatabaseConnector} from "./useDatabaseConnector";
import {LLMType} from "../../types/LLMTypes";
import {ConflictError} from "../../errors/ConflictError";
import {useCallback, useMemo} from "react";


export const useLLMEndpoints = (token: string, abortController: AbortController | null = null) => {
    const dbConnector = useDatabaseConnector(token, abortController)

    /**
     * Returns a promise that resolves to the llm entry with the given id
     * @param llm_id
     */
    const getLLM = useCallback((
        llm_id: number
    ): Promise<LLMType> => {
        return dbConnector.get(
            "/llm",
            {
                params: {
                    id: `eq.${llm_id}`
                }
            }
        ).then(response => response.data[0] as LLMType)
    }, [dbConnector])

    const getAllLLMs = useCallback((): Promise<LLMType[]> => {
        return dbConnector.get(
            "/llm"
        ).then(response => response.data as LLMType[])
    }, [dbConnector])

    const createLLM = useCallback((
        name: string
    ) => {
        return dbConnector.post(
            "/llm",
            {
                name
            }
        ).catch(error => {
            const HTTP_CONFLICT = 409
            if (error.response.status === HTTP_CONFLICT) {
                throw new ConflictError("An LLM with this name does already exist!")
            }
        })
    }, [dbConnector])

    return useMemo(() => {
        return {getLLM, getAllLLMs, createLLM}
    }, [getLLM, getAllLLMs, createLLM])
}
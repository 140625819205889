import "./PromptView.scss"
import React, {HTMLAttributes, useCallback, useEffect, useState} from 'react';
import {EmptyPrompt, EmptyPromptVotingResultType, PromptType, PromptVotingResultType} from "../../../types/Prompt";
import {CategoryType} from "../../../types/CategoryType";
import {TextPlaceholder, TextPlaceholderSubstitution} from "../../../types/TextPlaceholder";
import {useAuth} from "react-oidc-context";
import {useDatabase} from "../../../connectors/useDatabase";
import _ from "lodash";
import {Card} from "react-bootstrap";
import LongText from "../../LongText/LongText";
import {Link} from "react-router-dom";
import {PlaceholderSubstitutionCtx} from "../../../context/PlaceholderSubstitutionCtx/PlaceholderSubstitutionCtx";
import CategoryList from "../../Category/CategoryList/CategoryList";
import PromptViewRating from "../PromptViewRating/PromptViewRating";
import PromptViewCreateRating from "../PromptViewCreateRating/PromptViewCreateRating";

// the maximum height of the prompt text when not expanded
const MAXIMUM_TEXT_HEIGHT = 300 // in px

interface PromptViewProps extends HTMLAttributes<HTMLDivElement> {
    prompt_id: number,
    expandable?: boolean,
    showLink?: boolean,
    showPromptRatingButtons?: boolean
}

/**
 * This component visualizes a specific prompt. It queries the prompt content from the database and visualizes it as
 * a bootstrap card.
 * It can be displayed as expandable/collapsable component.
 *
 * It supports the substitution of placeholders inside the prompt text.
 *
 * @param prompt_id the id of the prompt that should be displayed.
 * @param expandable whether this component should be expandable
 * @param showLink whether the title, i.e., the prompt description, should be rendered as linked to the detailed
 *      page of this prompt.
 * @param showPromptRatingButtons whether the rating buttons should be shown underneath the prompt text
 * @param props other properties that are directly passed to the top-level enclosing div-component
 * @constructor
 */
function PromptView({
                        prompt_id,
                        expandable,
                        showLink,
                        showPromptRatingButtons,
                        ...props
                    }: PromptViewProps) {
    const [prompt, setPrompt] = useState<PromptType>(EmptyPrompt)
    const [promptVotingResult, setPromptVotingResult] = useState<PromptVotingResultType>(EmptyPromptVotingResultType)
    const [categories, setCategories] = useState<CategoryType[]>([])
    const [placeholders, setPlaceholders] = useState<TextPlaceholder[]>([])
    const [substitutions, setSubstitutions] = useState<TextPlaceholderSubstitution>({})
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const auth = useAuth()
    const db = useDatabase(auth.user?.access_token ?? '')

    const setSingleSubstitution = useCallback((key: string, substitution: string) => {
        let newSubstitutions = _.cloneDeep(substitutions)
        newSubstitutions[key] = substitution
        setSubstitutions(newSubstitutions)
    }, [substitutions])

    useEffect(() => {
        db.getPrompt(prompt_id).then(({prompt, categories, placeholders}) => {
            setPrompt(prompt)
            setCategories(categories)
            setPlaceholders(
                placeholders.map(({placeholder}) => placeholder) // remove the id and just save the placeholder
            )
            setIsLoading(false)
        }).catch(() => setIsLoading(false))
    }, [prompt_id, db])

    useEffect(() => {
        db.getPromptVotings(prompt_id).then(setPromptVotingResult)
    }, [prompt_id, db])


    const fetchPromptVotings = () => db.getPromptVotings(prompt_id).then(setPromptVotingResult)

    const titleComponent = showLink ?
        <Link to={`/prompt/${prompt.id}`}
              className={'default-color prompt-title-link'}
        >
            {prompt.description}
        </Link>
        :
        <div>{prompt.description}</div>;

    return (
        <PlaceholderSubstitutionCtx.Provider value={{substitutions, setSubstitution: setSingleSubstitution}}>
            <div  {...props} tabIndex={undefined} className={'prompt-view'}>
                <Card className={'prompt-view-card'}>
                    <Card.Header>
                        <Card.Title className={'prompt-view-title-line'}>
                            <h5>
                                {titleComponent}
                            </h5>
                            <div>
                                <PromptViewRating prompt_voting={promptVotingResult} className={'prompt-view-rating'}/>
                            </div>
                        </Card.Title>
                        <Card.Subtitle>
                            <CategoryList categories={categories}/>
                        </Card.Subtitle>
                    </Card.Header>
                    <Card.Body>
                        <LongText text={prompt.text}
                                  placeholders={placeholders}
                                  className={'prompt-text-container'}
                                  maxHeight={expandable? MAXIMUM_TEXT_HEIGHT : undefined}
                        />
                        {
                            showPromptRatingButtons && <div>
                                <div className={'text-separator prompt-view-rating-separator'}/>
                                <PromptViewCreateRating prompt_id={prompt_id}
                                                        className={'prompt-view-rating-buttons'}
                                                        onRatingSubmitted={fetchPromptVotings}
                                />
                            </div>
                        }
                    </Card.Body>
                    <Card.Footer>
                        <div
                            className={'footnote-text prompt-footer'}
                        >
                            <span>
                                von {prompt.created_by} am {prompt.created_at.toLocaleDateString()}
                            </span>
                            <span>
                                v{prompt.version} (Stand {prompt.last_changed_at.toLocaleDateString()})
                            </span>
                        </div>
                    </Card.Footer>
                </Card>
            </div>
        </PlaceholderSubstitutionCtx.Provider>
    )
}

export default PromptView;
import React from 'react';
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";

import "./Home.scss"

function Home() {
    return (<div>
        <Button>
            <Link to={'/prompts'} className={'default-color'}>
                Alle Prompts
            </Link>
        </Button>
    </div>)
}

export default Home;
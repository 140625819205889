import "./PromptForm.scss"
import React, {ChangeEventHandler, FormEventHandler, useEffect, useState} from 'react';
import {Button, Card, Form, FormProps} from "react-bootstrap";
import AutoResizingTextArea from "../../AutoResizingTextArea/AutoResizingTextArea";
import CategorySelect from "../../Category/CategorySelect/CategorySelect";
import EditPlaceholders from "../EditPlaceholders/EditPlaceholders";
import {TextPlaceholder} from "../../../types/TextPlaceholder";

interface PromptFormProps extends FormProps {
    defaultPromptText?: string
    defaultPromptDescription?: string,
    defaultSelectedCategoryIds?: number[],
    defaultSelectedPlaceholderKeys?: string[],
    created_by?: string,
    created_at?: Date,
    onCancel?: () => void
    onSubmitPrompt: (prompt: string, description: string, selectedCategoryIds: number[], selectedPlaceholders: TextPlaceholder[]) => void
}

/**
 * This component represents a form to create/edit a prompt.
 * It is basically an editable PromptView.
 * This component does not handle the actual creation/updating. Use therefore the respective callbacks.
 *
 * You can provide default values for each value of the prompt.
 *
 * @param defaultPromptText the default prompt text that should be displayed before any user interaction
 * @param defaultPromptDescription the default prompt description that should be displayed before any user interaction
 * @param defaultSelectedCategoryIds list of default category ids of this prompt
 * @param defaultSelectedPlaceholderKeys list of placeholder keys that are selected by default.
 *      Any found placeholder in the default prompt text, that is not in this list, is deselected by default.
 * @param created_by optionally add a name that should be displayed as creator (NOTE: this is not editable!)
 * @param created_at optionally add a timestamp that should be displayed as creation time (NOTE: this is not editable!)
 * @param onCancel callback that is called when the user cancels the editing, e.g., by pressing the cancel button.
 * @param onSubmitPrompt callback that is called with all information needed to create/update a prompt.
 *      It is called when the users saves the changes.
 * @param props general properties that are passed to the top-level Bootstrap Form component.
 * @constructor
 */
function PromptForm({
                        defaultPromptText,
                        defaultPromptDescription,
                        defaultSelectedCategoryIds,
                        defaultSelectedPlaceholderKeys,
                        created_by,
                        created_at,
                        onCancel,
                        onSubmitPrompt,
                        ...props
                    }: PromptFormProps) {
    const [promptText, setPromptText] = useState<string>(defaultPromptText ?? '')
    const [promptDescription, setPromptDescription] = useState<string>(defaultPromptDescription ?? '')
    const [selectedCategoryIds, setSelectedCategoryIds] = useState<number[]>(defaultSelectedCategoryIds ?? [])

    const [allPlaceholderKeys, setAllPlaceholderKeys] = useState<string[]>([])
    const [selectedPlaceholders, setSelectedPlaceholders] = useState<TextPlaceholder[]>([])
    const [defaultDeselectedPlaceholderKeys, setDefaultDeselectedPlaceholderKeys] = useState<string[]>([])

    const [validated, setValidated] = useState<boolean>(false)
    const [invalidateTimeoutId, setInvalidateTimeoutId] = useState<NodeJS.Timeout>()

    // reset default values as they may change due to delayed fetching of the data in the parent component
    useEffect(() => setPromptText(defaultPromptText ?? ''), [defaultPromptText])
    useEffect(() => setPromptDescription(defaultPromptDescription ?? ''), [defaultPromptDescription])
    useEffect(() => setSelectedCategoryIds(defaultSelectedCategoryIds ?? []), [defaultSelectedCategoryIds])

    // parsing all placeholder keys from current prompt text
    useEffect(() => {
        setAllPlaceholderKeys(TextPlaceholder.findPlaceholderKeys(promptText))
    }, [promptText])

    // deselect per default all placeholders that are in the defaultPromptText, but not defaultSelectedPlaceholderKeys
    useEffect(() => {
        const defaultPlaceholderKeys = TextPlaceholder.findPlaceholderKeys(defaultPromptText ?? '')
        setDefaultDeselectedPlaceholderKeys(defaultPlaceholderKeys.filter(key => !defaultSelectedPlaceholderKeys?.includes(key)))
    }, [defaultPromptText, defaultSelectedPlaceholderKeys])

    const checkValidity = (event: React.FormEvent<HTMLFormElement>): boolean => {
        event.preventDefault()
        clearTimeout(invalidateTimeoutId) // to avoid race conflicts

        setValidated(true);
        if (!event.currentTarget.checkValidity()) {
            return false
        }

        // if the form is valid, we reset the validation state after 2 seconds.
        // if we wouldn't do this, the green markings to indicate a valid input would stay forever
        setInvalidateTimeoutId(setTimeout(() => setValidated(false), 2 * 1000))
        return true
    }

    const handleChange: FormEventHandler<HTMLFormElement> = (event) => {
        // we only (re)check the validity if the form was originally validated once.
        // Hence, if the form was invalid, the new validity will be instantly checked, however not if the form is
        // just filled for the first time.
        if (validated) {
            checkValidity(event)
        }
    }

    const handleDescriptionChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setPromptDescription(event.target.value)
    }

    const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
        if (!checkValidity(event)) {
            // form is invalid => do nothing. The checkValidity function enables the feedback for the input fields.
            return
        }
        // submit callback. It should actually create/update the prompt
        onSubmitPrompt(promptText, promptDescription, selectedCategoryIds, selectedPlaceholders)
    }

    return (
        <Form className={'edit-prompt'}
              {...props}
              onSubmit={handleSubmit}
              onChange={handleChange}
              noValidate={true}
              validated={validated}
        >
            <Card>
                <Card.Header>
                    <Card.Title className={'edit-prompt-title-line'}>
                        <Form.Label>Beschreibung/Zusammenfassung des Prompts:</Form.Label>
                        <Form.Control
                            required
                            type={'input'}
                            value={promptDescription}
                            onChange={handleDescriptionChange}
                            placeholder={'Kurze Beschreibung des Prompts als Überschrift.'}
                        />
                        <Form.Control.Feedback type="invalid" className={'edit-prompt-title-feedback'}>
                            Die Überschrift darf nicht leer sein.
                        </Form.Control.Feedback>
                    </Card.Title>
                    <Card.Subtitle>

                    </Card.Subtitle>
                </Card.Header>
                <Card.Body>
                    <Form.Label>Gesamter Prompt:</Form.Label>
                    <AutoResizingTextArea value={promptText} onChangeValue={setPromptText} required={true}/>
                    <Form.Control.Feedback type="invalid">
                        Der Prompt darf nicht leer sein.
                    </Form.Control.Feedback>
                </Card.Body>
                <Card.Footer>
                    {created_by !== undefined && created_at !== undefined &&
                        <div
                            className={'footnote-text'}>von {created_by} am {created_at.toLocaleDateString()}
                        </div>
                    }
                </Card.Footer>
            </Card>
            <h5>Kategorien:</h5>
            <CategorySelect selectedCategoryIds={selectedCategoryIds} setSelectedCategoryIds={setSelectedCategoryIds}/>
            <EditPlaceholders
                placeholderKeys={allPlaceholderKeys}
                onChangeSelectedPlaceholders={setSelectedPlaceholders}
                defaultDeselectedPlaceholderKeys={defaultDeselectedPlaceholderKeys}
            />
            <div className={'edit-prompt-button'}>
                <Button variant={'secondary'} onClick={onCancel}>
                    Abbrechen
                </Button>
                <Button type={'submit'} variant={'primary'}>
                    Speichern
                </Button>
            </div>
        </Form>
    )
}

export default PromptForm;
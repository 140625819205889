import {usePromptEndpoints} from "./hooks/usePromptEndpoints";
import {useCategoryEndpoints} from "./hooks/useCategoryEndpoints";
import {useAnswerEndpoints} from "./hooks/useAnswerEndpoints";
import {useLLMEndpoints} from "./hooks/useLLMEndpoints";
import {useCommentEndpoints} from "./hooks/useCommentEndpoints";
import {usePromptRevisionEndpoints} from "./hooks/usePromptRevisionEndpoints";
import {usePromptVotingEndpoints} from "./hooks/usePromptVotingEndpoints";
import {useCallback, useMemo} from "react";

export const useDatabase = (token: string, abortController: AbortController | null = null) => {
    const promptEndpoints = usePromptEndpoints(token, abortController);
    const promptRevisionEndpoints = usePromptRevisionEndpoints(token, abortController)
    const promptVotingEndpoints = usePromptVotingEndpoints(token, abortController);
    const categoryEndpoints = useCategoryEndpoints(token, abortController);
    const answerEndpoints = useAnswerEndpoints(token, abortController);
    const commentEndpoints = useCommentEndpoints(token, abortController);
    const llmEndpoints = useLLMEndpoints(token, abortController);

    const abortAll = useCallback((reason: any = null) => {
        abortController?.abort(reason)
    }, [abortController])

    return useMemo(() => {
        return {
            abortController,
            abortAll,
            ...promptEndpoints,
            ...promptRevisionEndpoints,
            ...promptVotingEndpoints,
            ...categoryEndpoints,
            ...answerEndpoints,
            ...commentEndpoints,
            ...llmEndpoints
        }
    }, [abortController, abortAll, promptEndpoints, promptRevisionEndpoints, promptVotingEndpoints, categoryEndpoints, answerEndpoints, commentEndpoints, llmEndpoints])
}
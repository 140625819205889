import React, {useRef, useState} from 'react';
import './CopyButton.css'
import {FaRegClipboard} from "react-icons/fa";
import {Overlay} from "react-bootstrap";

interface CopyButtonProps {
    text: string
}

function CopyButton({text}: CopyButtonProps) {
    const copyIconRef = useRef(null);
    const [showCopiedText, setShowCopiedText] = useState(false);

    const clickCopy = () => {
        navigator.clipboard.writeText(text).then(() => {
                setShowCopiedText(true)
                setTimeout(() => setShowCopiedText(false), 2000)
            }
        )
    }

    return (
        <>
            <div onClick={clickCopy} className={'clickable'} ref={copyIconRef}>
                <FaRegClipboard/>
            </div>
            <Overlay target={copyIconRef.current} show={showCopiedText} placement="bottom">
                {({
                      placement: _placement,
                      arrowProps: _arrowProps,
                      show: _show,
                      popper: _popper,
                      hasDoneInitialMeasure: _hasDoneInitialMeasure,
                      ...props
                  }) => (
                    <div className={'copied-text-overlay'} {...props}>
                        kopiert
                    </div>
                )}
            </Overlay>
        </>
    )
}

export default CopyButton;